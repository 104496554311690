import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { range } from 'ramda';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import * as NewReport from '@ducks/newReport';
import * as Form from '@ducks/form';
import * as Template from '@ducks/template';
import * as Config from '@ducks/config';
import * as User from '@ducks/user';
import { Button, createSnackNotification, AlertLevel } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import './NewReportDrawer.scss';
import { map } from 'lodash';

interface Props extends PropsFromRedux {
  closeDrawer: () => void;
}

const NewReportDrawer = ({ assignment, setAssignment, formLoading, otherAssignment, setOtherAssignment, currentSubstep, numberOfPeople, yearsOfExperience, setYearsOfExperience,
  currentStep, closeDrawer, nSteps, stopId, saveAndQuit, setStartOverDialogOpen, setUserSettingsDialogOpen, officerAssignment, resetHistory, breakpoint, locationString,
  flags: { pre2024: before2024, post2024, is2024Test } }: Props) => {
  const otherAssignmentForm = useRef<HTMLElement>(null);
  const officerAssignmentVal = officerAssignment?.OfficerTypeofAssignment?.possibleValues;
  const handleSubStep = (si: number, ssi: number) => {
    if (ssi <= currentSubstep) {
      resetHistory(si, ssi);
    }
  }

  let pre2024 = before2024

  if (post2024) {
    pre2024 = post2024;
  }

  const assignmentDropdown = (pre2024) && 'OfficerTypeofAssignment' in officerAssignment && 'OffDuty' in officerAssignmentVal && 'ContractedByAnotherLEA' in officerAssignmentVal

  const handleStep = (si: number) => {
    if (si < currentStep) {
      resetHistory(si, false);
    }
  }

  const setDefaultAssignment = () => {
    const assignmentValue = localStorage.getItem('defaultAssignment') ?? officerAssignmentVal?.Patrol?.value;
    const val = assignmentValue !== officerAssignmentVal?.Other?.value && assignmentValue !== '' ? assignmentValue : officerAssignmentVal?.Patrol?.value
    setAssignment(val);
    setOtherAssignment('');
    localStorage.setItem('defaultAssignment', val);
  }

  const onAssignmentClick = (value: string) => {
    const updatedDefaultAssignment = map(officerAssignmentVal, 'value').includes(value) && (pre2024 || assignmentTypes.includes(value)) ? value : officerAssignmentVal?.Patrol?.value
    if (updatedDefaultAssignment && updatedDefaultAssignment !== officerAssignmentVal?.Other?.value) {
      localStorage.setItem('defaultAssignment', updatedDefaultAssignment);
    }
    setAssignment(updatedDefaultAssignment);
  }

  const handleCloseDrawer = () => {
    if ((assignment && assignment !== 'Other') || (assignment === 'Other' && otherAssignment)) {
      closeDrawer();
    } else {
      const label = assignment === 'Other' ? 'Other Assignment field' : 'Assignment field';
      createSnackNotification(AlertLevel.Error, 'Error', `Fill the ${label}`);
    }
  }

  useEffect(() => {
    if (assignment === 'Other') {
      otherAssignmentForm?.current?.focus();
    }
  }, [assignment])

  useEffect(() => {
    const assignmentVal = localStorage.getItem('defaultAssignment');
    if (assignmentVal && assignmentVal !== '' && assignment === '') {
      if ((!pre2024 && assignmentTypes.includes(assignmentVal)) || (pre2024 && !is2024Test)) {
        setAssignment(assignmentVal);
      }
    }
  }, [pre2024])

  const assignmentTypes = [
    officerAssignmentVal?.Patrol.value,
    officerAssignmentVal?.Gang.value,
    officerAssignmentVal?.Compliance.value,
    officerAssignmentVal?.SpecialEvents.value,
    officerAssignmentVal?.RoadblockDUISobrietyCheckpoint.value,
    officerAssignmentVal?.Narcotics.value,
    officerAssignmentVal?.TaskForce.value,
    officerAssignmentVal?.K12.value,
    officerAssignmentVal?.Investigative.value,
    officerAssignmentVal?.Other.value,
  ];

  return (
    <div className="new-report-drawer" data-testid="new-report-drawer">
      <div className="new-report-drawer__heading">
        CONTACT
        <div
          className="material-icons"
          tabIndex={0}
          onClick={handleCloseDrawer}
          onKeyUp={onEnter(handleCloseDrawer)}
          role="button"
          aria-label="Close drawer"
        >
          close
        </div>
      </div>
      <div className="new-report-drawer__info">
        <div className="new-report-drawer__info-stop-id">
          <span className="material-icons">description</span>
          <span className="new-report-drawer__info-stop-id-title">STOP ID</span>
          <span className="new-report-drawer__info-stop-id-id">{stopId}</span>
        </div>
        <div className="new-report-drawer__info-suspects">
          <span className="material-icons">person</span>
          <span className="new-report-drawer__info-suspects-title">
            CONTACTS
          </span>
          <span
            className="new-report-drawer__info-suspects-num"
            data-testid="new-report-drawer-info-suspects-num"
          >
            {currentStep >= 1 && currentSubstep >= 0 ? numberOfPeople : '---'}
          </span>
        </div>
        <div
          className={cn('new-report-drawer__info-location', {
            'has-value': locationString !== '',
          })}
        >
          <span className="material-icons">place</span>
          <div className="new-report-drawer__info-location-section">
            <span className="new-report-drawer__info-location-title">
              LOCATION
            </span>
            <div className="new-report-drawer__info-location-address-1">
              {locationString || '---'}
            </div>
          </div>
        </div>
      </div>
      {breakpoint !== 'xs' && <>
        {(!pre2024) && !assignmentDropdown && officerAssignmentVal && assignment !== 'Other' && otherAssignment === '' && (
          <FormControl
            variant="outlined"
            className="new-report-drawer__assignment-dropdown"
          >
            <InputLabel id="new-report-drawer-assignment-label">
              Assignment
            </InputLabel>
            <Select
              labelId="new-report-drawer-assignment-label"
              id="new-report-drawer-assignment"
              value={assignment}
              onChange={({ target: { value } }: any) => onAssignmentClick(value)}
              label="assignment"
              native={breakpoint === 'xs'}
              data-testid="new-report-drawer-assignment"
              MenuProps={{
                variant: 'menu',
                getContentAnchorEl: null
              }}
            >
              {assignmentTypes.map((t, i) => {
                const CompName = breakpoint === 'xs' ? 'option' : MenuItem;
                return (<CompName value={t} key={`assignment-option-${i}`}>
                  {t}
                </CompName>);
              })}
            </Select>
          </FormControl>
        )}
        {(!assignmentDropdown && assignment === officerAssignmentVal?.Other.value ||
          otherAssignment !== '') && (
            <TextField
              inputRef={otherAssignmentForm}
              label="Other Assignment"
              id="new-report-drawer__other-assignment"
              className="new-report-drawer__other-assignment"
              InputProps={{
                endAdornment: (
                  <div className="new-report-drawer__other-assignment-controls">
                    <div
                      className="new-report-drawer__other-assignment-back material-icons"
                      role="button"
                      tabIndex={0}
                      onClick={setDefaultAssignment}
                      onKeyUp={onEnter(setDefaultAssignment)}
                    >
                      close
                    </div>
                  </div>
                ),
              }}
              placeholder="Enter Other Assignment Title..."
              value={otherAssignment}
              onChange={(e) => setOtherAssignment(e?.target?.value)}
              onBlur={() => setOtherAssignment(otherAssignment)}
              variant="outlined"
              type="text"
              data-testid="new-report-drawer__other-assignment"
              required
            />
          )}
      </>}
      {formLoading ? <div /> : ((!pre2024)) && <TextField
        label="Years of Experience"
        className="new-report-drawer__years-of-experience"
        InputProps={{
          endAdornment: (
            <div className="new-report-drawer__years-of-experience-controls">
              <div
                className="new-report-drawer__years-of-experience-controls-up"
                data-testid="new-report-drawer__years-of-experience-controls-up"
                role="button"
                tabIndex={0}
                onClick={() =>
                  yearsOfExperience <= 49
                    ? setYearsOfExperience({
                      yearsOfExperience: yearsOfExperience + 1,
                    })
                    : null
                }
                onKeyUp={onEnter(() =>
                  yearsOfExperience <= 49
                    ? setYearsOfExperience({
                      yearsOfExperience: yearsOfExperience + 1,
                    })
                    : null
                )}
              >
                +
              </div>
              <div
                className="new-report-drawer__years-of-experience-controls-down"
                data-testid="new-report-drawer__years-of-experience-controls-down"
                role="button"
                tabIndex={0}
                onClick={() =>
                  yearsOfExperience > 1
                    ? setYearsOfExperience({
                      yearsOfExperience: yearsOfExperience - 1,
                    })
                    : null
                }
                onKeyUp={onEnter(() =>
                  yearsOfExperience > 1
                    ? setYearsOfExperience({
                      yearsOfExperience: yearsOfExperience - 1,
                    })
                    : null
                )}
              >
                -
              </div>
            </div>
          ),
        }}
        value={yearsOfExperience}
        variant="outlined"
        type="number"
      />}
      {formLoading ? <Skeleton className="new-report-drawer__skeleton-user-set" width={281} height={50} /> :
        (pre2024 || breakpoint === 'xs') &&
        <Button
          className="new-report-drawer__user-settings"
          data-testid="new-report-drawer-user-settings"
          priority="secondary"
          materialIcon="settings"
          large={true}
          onClick={() => setUserSettingsDialogOpen({ open: true })}
        >
          USER SETTINGS
        </Button>
      }
      <div className="new-report-drawer__steps">
        {formLoading && <>
          {range(0, 5).map((_, ssi) => <>
            <span className="new-report-drawer__step-skeleton-cont" key={`new-report-drawer-step-skeleton-${ssi}`}>
              <Skeleton className="new-report-drawer__step-skeleton-circ" variant="circle" width={40} height={40} />
              <span>
                <Skeleton className="new-report-drawer__step-skeleton-rect" width={170} height={16} />
                <span className="new-report-drawer__step-skeleton-cont-sm-circ">
                  <Skeleton className="new-report-drawer__step-skeleton-sm-circ" variant="circle" width={10} height={10} />
                  <Skeleton className="new-report-drawer__step-skeleton-sm-circ" variant="circle" width={10} height={10} />
                  <Skeleton className="new-report-drawer__step-skeleton-sm-circ" variant="circle" width={10} height={10} />
                  <Skeleton className="new-report-drawer__step-skeleton-sm-circ" variant="circle" width={10} height={10} />
                </span>
              </span>
            </span>
          </>)}
        </>}
        {!formLoading && (nSteps || []).map(({ title, nSubsteps }, si) => (
          <div
            className={cn('new-report-drawer__step', {
              done: si < currentStep,
              future: si > currentStep,
            })}
            key={`NrDrawerStep-${si}`}
          >
            <div className="new-report-drawer__step-graph">
              <div
                className="new-report-drawer__step-graph-circle"
                tabIndex={si}
                role="button"
                onClick={() => handleStep(si)}
                onKeyUp={onEnter(() => handleStep(si))}
              >
                <div className="new-report-drawer__step-graph-circle-num">
                  {si + 1}
                </div>
                <div className="material-icons">done</div>
              </div>
              <div className="new-report-drawer__step-graph-line" />
            </div>
            <div className="new-report-drawer__step-detail">
              <div className="new-report-drawer__step-detail-title">
                {title}
              </div>
              {range(0, nSubsteps).map((_, ssi) => (
                <div
                  className={cn('new-report-drawer__step-detail-bubble', {
                    done: ssi <= currentSubstep,
                  })}
                  key={`NrDrawerSubStep-${ssi}`}
                  tabIndex={ssi}
                  role="button"
                  onClick={() => handleSubStep(si, ssi)}
                  onKeyUp={onEnter(() => handleSubStep(si, ssi))}
                />
              ))}
            </div>
            <div className="new-report-drawer__step-selected-border" />
          </div>
        ))}
      </div>
      <div className="new-report-drawer__bottom">
        <Button
          className="new-report-drawer__start-over-btn"
          data-testid="new-report-drawer-start-over-btn"
          priority="secondary"
          materialIcon="refresh"
          large={true}
          onClick={() => setStartOverDialogOpen({ open: true, formId: stopId })}
        >
          START OVER
        </Button>
        <Button
          className="new-report-drawer__save-btn"
          data-testid="new-report-drawer-save-btn"
          priority="primary"
          materialIcon="cloud_download"
          large={true}
          onClick={saveAndQuit}
        >
          SAVE DRAFT
        </Button>
      </div>
    </div>
  );
}

NewReportDrawer.defaultProps = {
  officerAssignment: {
    Patrol: {
      key: 1,
      value: 'Patrol, traffic, field operations'
    },
    Gang: {
      key: 2,
      value: 'Gang enforcement'
    },
    Compliance: {
      key: 3,
      value: 'Compliance check'
    },
    SpecialEvents: {
      key: 4,
      value: 'Special events'
    },
    RoadblockDUISobrietyCheckpoint: {
      key: 5,
      value: 'Roadblock or DUI sobriety checkpoint'
    },
    Narcotics: {
      key: 6,
      value: 'Narcotics/vice'
    },
    TaskForce: {
      key: 7,
      value: 'Task force'
    },
    K12: {
      key: 8,
      value: 'K-12 public school officer, school resource or police officer'
    },
    Investigative: {
      key: 9,
      value: 'Investigative/detective'
    },
    Other: {
      key: 10,
      value: 'Other'
    }
  }
} as any; // This is unfortunate currently - should be fixed when the selector type is updated

const mapDispatchToProps = {
  setAssignment: Form.setFormField({ path: ['assignment'] }),
  setOtherAssignment: Form.setFormField({ path: ['otherAssignment'] }),
  saveAndQuit: Form.saveAndQuit,
  setStartOverDialogOpen: NewReport.setStartOverDialogOpen,
  setYearsOfExperience: NewReport.setYearsOfExperience,
  resetHistory: NewReport.resetHistory,
  setUserSettingsDialogOpen: User.setUserSettingsDialogOpen
}

const mapStateToProps = (state: any) => ({
  formLoading: Form.selectors.formLoading(state),
  currentStep: NewReport.selectors.currentStep(state),
  currentSubstep: NewReport.selectors.currentSubstep(state),
  nSteps: NewReport.selectors.nSteps(state),
  assignment: Form.selectors.assignment(state),
  otherAssignment: Form.selectors.otherAssignment(state),
  yearsOfExperience: Form.selectors.yearsOfExperience(state),
  stopId: Form.selectors.stopId(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  officerAssignment: Template.selectors.enumMap(state),
  breakpoint: Config.selectors.breakpoint(state),
  flags: Form.selectors.flags(state),
  locationString: Form.selectors.locationString(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NewReportDrawer);
