import React, { useCallback, useEffect, useRef } from 'react';
import { debounce, isArray, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { connect, ConnectedProps } from 'react-redux';
import { TextField, InputAdornment, MenuItem, Select, FormControl, LinearProgress } from '@material-ui/core';
import noElasticScroll from 'inobounce';
import { onEnter } from '@utility/keypressHelpers';
import { sortText, sortTime } from '@utility/sort';
import { Button, ConfirmDialog, Column, Table, LabelDropdown, Loading } from '@components/common';
import { Header, UsersRow, UsersTableSelectColumn, AddUser, EditUser, MoreAdminUserActions } from '@components/custom';
import { Config, User, Users as UsersDuck, Organizations as OrganizationsDuck } from '@ducks';
import { Role } from '@ducks/constants';
import cn from 'classnames';
import './Users.scss';
import {
  Loading as LoadingUserStatus,
  Dialog as DialogUser,
} from '@engine/ducks/types';

interface Props extends PropsFromRedux {
  loading: LoadingUserStatus;
  dialog: DialogUser;
}

export const Users = ({
  userRows,
  getUsers,
  pagination,
  setPagination,
  setRole,
  setReviewer,
  bulkReviewerSelection,
  authProvider,
  dialog,
  searchAndFilter,
  setSort,
  setSearch,
  loading,
  assignedRoles,
  reviewerSelection,
  roleSelection,
  reviewerDropdowns,
  setBulkReviewer,
  setAddUserDialogOpen,
  editUserDialog,
  exportUsersToCsv,
  disableUserDialog,
  setEditUserDialogOpen,
  setRemovingReviewerDialogOpen,
  setDisableUserDialogOpen,
  disableUser,
  updateUser,
  getAllReviewers,
  bulkCreateReviewerships,
  userId,
  breakpoint,
  addReviewersRemaining,
  getOrganizations,
  orgs,
}: Props) => {
  const { sort } = searchAndFilter || {};
  const scrollContainer = useRef<HTMLDivElement>(null);
  const fetchMore = useCallback(
    debounce((event) => {
      if (event?.target) {
        const { scrollHeight: sh, scrollTop: st, clientHeight: ch } = event.target;
        if (sh - st - ch < 10) {
          const { currentPage, pageSize, totalPages } = pagination || {};
          if (currentPage && totalPages && searchAndFilter && currentPage < totalPages) {
            getUsers({ pagination: { pageSize, currentPage: currentPage + 1 }, searchAndFilter, isFetchMore: true });
          }
        }
      }
    }, 50),
    [pagination]
  )

  useEffect(() => {
    if (pagination && searchAndFilter) {
      if (assignedRoles?.includes(Role.SuperAdmin)) {
        getOrganizations({ searchAndFilter, pagination });
      }
      getUsers({ pagination, searchAndFilter });
      getAllReviewers();
    }

    /* Disable 'no elastic scroll' on Safari iOS */
    noElasticScroll.disable();
    return () => {
      /* Enable 'no elastic scroll' on Safari iOS */
      noElasticScroll.enable();
    }
  }, [])

  useEffect(() => {
    const { current } = scrollContainer;
    current?.addEventListener('scroll', fetchMore);
    return () => {
      current?.removeEventListener('scroll', fetchMore);
    }
  }, [fetchMore])

  const handleExportCSV = () => {
    exportUsersToCsv();
  };

  const newUserRows = userRows.map((user) => ({
    ...user,
    orgName: orgs.find((org: { id: number }) => org.id === user.orgId)?.name || '',
  }));

  const modifiedReviewerDropdowns = [{ id: 'all', name: 'All Reviewers' }].concat(reviewerDropdowns);
  const handleSelectReviewer = (value: string[]) => {
    const isBulkReviewerSelectionArray = isArray(bulkReviewerSelection);
    const valueWithoutAll = value.filter((v) => v !== 'all');
    const includesAll = value.includes('all');

    const selectAll = includesAll || (isBulkReviewerSelectionArray && value.length === reviewerDropdowns.length && !includesAll && !bulkReviewerSelection.includes('all'));
    const deselectAll = value.length === reviewerDropdowns.length && !includesAll && isBulkReviewerSelectionArray && bulkReviewerSelection.length === reviewerDropdowns.length;
    const deselectOne = isBulkReviewerSelectionArray && bulkReviewerSelection.length === reviewerDropdowns.length && includesAll;

    if (deselectOne || !selectAll) {
      setBulkReviewer(valueWithoutAll);
    } else if (deselectAll) {
      setBulkReviewer([]);
    } else if (selectAll) {
      setBulkReviewer(reviewerDropdowns.map((r) => r.id));
    }
  }
  const renderSelectedReviewers = (selected: any) => {
    if (selected.includes('all')) {
      return 'All Reviewers';
    }
    return selected?.map((s: any) => reviewerDropdowns?.find((r) => r.id === s)?.name).join(', ')
  }
  const modifiedBulkReviewerSelection = isArray(bulkReviewerSelection) && !isEmpty(bulkReviewerSelection) && bulkReviewerSelection.length === reviewerDropdowns.length ? ['all'].concat(bulkReviewerSelection) : bulkReviewerSelection;
  /* eslint-disable react/jsx-key */
  return (
    <Header title="Contact">
      {breakpoint !== 'xs' && (
        <div className="users" data-testid="users">
          {!assignedRoles?.includes(Role.SuperAdmin) && (
            <div className="users__change-assignment">
              <div className="users__change-assignment-text">Select users below and then a select a reviewer here to assign them.</div>
              <div className="users__change-assignment-controls">
                <LabelDropdown
                  className="users__change-assignment-dropdown"
                  data-testid="users-dropdown-assign-reviewer"
                  multiple
                  values={modifiedReviewerDropdowns?.map(({ id: reviewerId, name }) => ({ value: reviewerId, text: name }))}
                  onChange={({ target: { value } }: any) => handleSelectReviewer(value)}
                  renderValue={renderSelectedReviewers}
                  value={modifiedBulkReviewerSelection}
                />
                <div
                  className="users__change-assignment-button"
                  aria-label="Assign selected users to a reviewer"
                  data-testid="users-change-assignment-button"
                  role="button"
                  tabIndex={0}
                  onClick={bulkCreateReviewerships}
                  onKeyUp={onEnter(bulkCreateReviewerships)}
                >
                  CHANGE ASSIGNMENT
                </div>
              </div>
            </div>
          )}
          <div className="users__search-container">
            <TextField
              className="users__search"
              variant="outlined"
              placeholder="Search..."
              onChange={({ target: { value } }: any) => setSearch(value || undefined)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div className="material-icons">search</div>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                'data-testid': 'users-search',
              }}
            />
            <FormControl variant="outlined" className="users__role-select">
              <Select value={roleSelection} onChange={({ target: { value } }: any) => setRole(value)} data-testid="users-dropdown-roles">
                <MenuItem value={Role.All}>All Roles</MenuItem>
                <MenuItem value={Role.Officer}>Officer</MenuItem>
                <MenuItem value={Role.Reviewer}>Reviewer</MenuItem>
                <MenuItem value={Role.Analyst}>Analyst</MenuItem>
                <MenuItem value={Role.Admin}>Admin</MenuItem>
              </Select>
            </FormControl>
            {!assignedRoles?.includes(Role.SuperAdmin) && <FormControl variant="outlined" className="users__reviewer-select">
              <Select value={reviewerSelection} onChange={({ target: { value } }: any) => setReviewer(value)} data-testid="filter-by-reviewer">
                <MenuItem value="all">All Reviewers</MenuItem>
                {reviewerDropdowns.map((r, i) => (
                  <MenuItem key={`AdminPageReviewerDrop-${i}`} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
            <div className="users__actions">
              {!assignedRoles?.includes(Role.SuperAdmin) && <div
                className={cn('users__add-user', { disabled: authProvider ? ['OneLogin', 'AzureAD'].includes(authProvider) : false })}
                aria-label="Approve selected report rows"
                data-testid="users-add-user"
                role="button"
                tabIndex={0}
                onClick={() => setAddUserDialogOpen({ open: true })}
                onKeyUp={onEnter(() => setAddUserDialogOpen({ open: true }))}
              >
                <div className="material-icons">add</div>
                <div className="users__add-user-text">ADD USER</div>
              </div>}
              {assignedRoles?.filter(r => r !== Role.SuperAdmin) && (
                <div
                  className={cn('users__export-csv', { disabled: authProvider ? ['OneLogin', 'AzureAD'].includes(authProvider) : false })}
                  aria-label="Export CSV report"
                  data-testid="users-export-csv"
                  role="button"
                  tabIndex={0}
                  onClick={handleExportCSV}
                  onKeyUp={onEnter(handleExportCSV)}
                >
                  <div className="users__export-csv-text">EXPORT USERS</div>
                </div>
              )}
            </div>
          </div>
          <div className="users__table" data-testid="users-table-kaka">
            <Table
              RowComponent={UsersRow}
              rowData={assignedRoles?.includes(Role.SuperAdmin) ? newUserRows : userRows}
              dataQuery={({ page, limit }: { page: number; limit: number }) =>
                sort && getUsers({
                  searchAndFilter: { ...searchAndFilter, sort },
                  pagination: { currentPage: page, pageSize: limit },
                })
              }
              loading={Boolean(loading.getUsers)}
              pagination={pagination}
              setSort={setSort}
              setPagination={setPagination}
              emptyMessage="There are no users in your organization"
            >
              <Column title="" dataKey="select" grow={1} minWidth={90} Component={UsersTableSelectColumn} />
              <Column title="Last name, First Name" dataKey="name" grow={1} minWidth={90} sort={sortText('name')} />
              <Column title="Username" dataKey="username" grow={4} minWidth={100} />
              {assignedRoles?.includes(Role.SuperAdmin) && <Column title="Org ID" dataKey="orgId" grow={1} minWidth={50} />}
              {assignedRoles?.includes(Role.SuperAdmin) && <Column title="Org Name" dataKey="orgName" grow={5} minWidth={100} />}
              <Column title="Officer ID" dataKey="officerId" grow={5} minWidth={100} />
              <Column title="Reviewers" dataKey="reviewers" grow={3} minWidth={100} />
              {breakpoint === 'lg' && <Column title="Role" dataKey="roles" grow={3} minWidth={90} sort={sortText('roles')} />}
              <Column title="Status" dataKey="status" grow={3} minWidth={90} />
              {breakpoint === 'lg' && <Column title="User Created" dataKey="created" grow={4} minWidth={75} sort={sortTime('created')} />}
              <Column title="Actions" dataKey="actions" grow={3} minWidth={breakpoint === 'lg' ? 170 : 75} />
            </Table>
          </div>
        </div>
      )}
      <ConfirmDialog heading="Adding new reviewers" open={loading.addReviewers}>
        <p>Adding new reviewers...</p>
        <p>{`${addReviewersRemaining} remaining.`}</p>
        <Loading />
      </ConfirmDialog>
      <ConfirmDialog
        heading="Add User"
        open={dialog.addUserDialogOpen}
        closeDialog={() => setAddUserDialogOpen({ open: false })}
        negativeAction={() => setAddUserDialogOpen({ open: false })}
        positiveText="Save"
        negativeText="Cancel"
      >
        <AddUser />
      </ConfirmDialog>
      <ConfirmDialog
        heading="Edit User"
        open={dialog.editUserDialogOpen}
        closeDialog={() => setEditUserDialogOpen({ open: false })}
        negativeAction={() => setEditUserDialogOpen({ open: false })}
        positiveText="Save"
        negativeText="Cancel"
      >
        <EditUser setRemovingReviewerDialogOpen={setRemovingReviewerDialogOpen} />
      </ConfirmDialog>
      <ConfirmDialog
        heading="Removing Reviewer"
        open={dialog.removingReviewerDialogOpen}
        closeDialog={() => setRemovingReviewerDialogOpen({ open: false })}
        negativeAction={() => setRemovingReviewerDialogOpen({ open: false })}
        positiveAction={updateUser}
        positiveText="Save"
        positiveActionTestId="removing-reviewer-confirm-dialog-yes-button"
        negativeText="Cancel"
      >
        <div className="removing-reviewer-dialog" data-testid="removing-reviewer-dialog">
          {`Are you sure you want to remove the reviewer role? This reviewer currently has ${editUserDialog?.reviewees.length || 0} reviewees.`}
        </div>
      </ConfirmDialog>
      <ConfirmDialog
        heading="Disable User"
        open={dialog.disableUserDialogOpen}
        closeDialog={() => setDisableUserDialogOpen({ open: false })}
        negativeAction={() => setDisableUserDialogOpen({ open: false })}
        positiveAction={disableUser}
        positiveText="Disable"
        negativeText="Cancel"
      >
        <div>{`The user ${disableUserDialog?.firstname || ''} ${disableUserDialog?.lastname || ''} will not be able to be re-enabled without contacting Veritone. Are you sure you want to disable this user?`}</div>
      </ConfirmDialog>
      {breakpoint === 'xs' && (
        <div className="users-mobile" data-testid="users-mobile">
          <div className="users-mobile__search-container" data-testid="users-mobile__search-container">
            <div className="users-mobile__search-add-user-container">
              <TextField
                className="users__search"
                variant="outlined"
                placeholder="Search..."
                onChange={({ target: { value } }: any) => setSearch(value || undefined)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className="material-icons">search</div>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  'data-testid': 'users-search',
                }}
              />
              <Button
                className="users-mobile__add-user"
                data-testid="users-mobile__add-user"
                priority="primary"
                materialIcon="add"
                disabled={Boolean(authProvider ? ['OneLogin', 'AzureAD'].includes(authProvider) : false)}
                allowClickOnDisable="true"
                onClick={() => setAddUserDialogOpen({ open: true })}
              >
                ADD USER
              </Button>
            </div>
            <FormControl variant="outlined" className="users__role-select">
              <Select value={roleSelection} onChange={({ target: { value } }: any) => setRole(value)} data-testid="users-dropdown-roles">
                <MenuItem value={Role.All}>All Roles</MenuItem>
                <MenuItem value={Role.Officer}>Officer</MenuItem>
                <MenuItem value={Role.Reviewer}>Reviewer</MenuItem>
                <MenuItem value={Role.Analyst}>Analyst</MenuItem>
                <MenuItem value={Role.Admin}>Admin</MenuItem>
                {/* <MenuItem value={Role.SuperAdmin}>Super Admin</MenuItem> */}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="users__reviewer-select">
              <Select value={reviewerSelection} onChange={({ target: { value } }: any) => setReviewer(value)} data-testid="filter-by-reviewer">
                <MenuItem value="all">All Reviewers</MenuItem>
                {reviewerDropdowns.map((r, i) => (
                  <MenuItem key={`AdminPageReviewerDrop-${i}`} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {loading.getUsers && <LinearProgress />}
          <div className="users-mobile__cards" id="users-mobile-cards" data-testid="users-mobile-cards" ref={scrollContainer}>
            {userRows.map(({ disabled, id, name, roles, username }, i) => (
              <div className="users-mobile__card" key={`UsersMobileCard-${i}`} data-testid={`users-mobile-card-${id}-${i}`}>
                <MoreAdminUserActions i={i} id={id} userId={userId} setEditUserDialogOpen={setEditUserDialogOpen} setDisableUserDialogOpen={setDisableUserDialogOpen} userDisabled={disabled} />
                <div className="users-mobile__card-name">{name}</div>
                <div className="users-mobile__card-username">{username}</div>
                <div className="users-mobile__card-details">
                  <div className="users-mobile__card-roles">
                    {roles.map((role) => (
                      <div className={cn('users-mobile__card-role', role)}>{role}</div>
                    ))}
                  </div>
                  <div className="users-mobile__card-status">{disabled ? 'Disabled' : 'Active'}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Header>
  )
}

const mapDispatchToProps = {
  setPagination: UsersDuck.setPagination,
  setSort: UsersDuck.setSortAndSearch,
  setSearch: UsersDuck.setSearch,
  getUsers: UsersDuck.getUsers,
  setRole: UsersDuck.setRole,
  setReviewer: UsersDuck.setReviewer,
  setAddUserDialogOpen: UsersDuck.setAddUserDialogOpen,
  setEditUserDialogOpen: UsersDuck.setEditUserDialogOpen,
  exportUsersToCsv: UsersDuck.exportUsersToCsv,
  setRemovingReviewerDialogOpen: UsersDuck.setRemovingReviewerDialogOpen,
  setDisableUserDialogOpen: UsersDuck.setDisableUserDialogOpen,
  disableUser: UsersDuck.disableUser,
  updateUser: UsersDuck.updateUser,
  getAllReviewers: UsersDuck.getAllReviewers,
  setBulkReviewer: UsersDuck.setBulkReviewer,
  bulkCreateReviewerships: UsersDuck.bulkCreateReviewerships,
  logout: User.logout,
  getOrganizations: OrganizationsDuck.getOrganizations,
}

const mapStateToProps = (state: any) => ({
  dialog: UsersDuck.selectors.dialog(state),
  loading: UsersDuck.selectors.loading(state),
  searchAndFilter: UsersDuck.selectors.searchAndFilter(state),
  pagination: UsersDuck.selectors.pagination(state),
  userRows: UsersDuck.selectors.userRows(state),
  assignedRoles: User.selectors.roles(state),
  roleSelection: UsersDuck.selectors.roleSelection(state),
  reviewerSelection: UsersDuck.selectors.reviewerSelection(state),
  reviewerDropdowns: UsersDuck.selectors.reviewerDropdowns(state),
  editUserDialog: UsersDuck.selectors.editUserDialog(state),
  disableUserDialog: UsersDuck.selectors.disableUserDialog(state),
  bulkReviewerSelection: UsersDuck.selectors.bulkReviewerSelection(state),
  authProvider: User.selectors.authProvider(state),
  userId: User.selectors.userId(state),
  breakpoint: Config.selectors.breakpoint(state),
  addReviewersRemaining: UsersDuck.selectors.addReviewersRemaining(state),
  orgs: OrganizationsDuck.selectors.orgs(state),
})

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Users)
