import { AlertLevel, createSnackNotification } from '@components/common/Snackbar/Snackbar';
import env from '@engine/env';
import { assignRoute } from '@utility/assignRoute';
import { assoc, path, pathOr, compose, map, assocPath, allPass, memoizeWith } from 'ramda';
import { decoupledDispatch } from '@utility/decoupledDispatch';
import { httpErrorHandler } from '@utility/httpErrorHandler';
import { setDarkMode, detectThemeChange } from '@utility/setDarkMode';
import { paths as Paths } from '@components/custom/Routes/paths';
import { get } from 'lodash';
import getOnlineTimeoutByEffectiveType from '@utility/getOnlineTimeoutByEffectiveType';
import stateBounds from '@utility/stateBounds';
import qs from 'qs';
import { clearTimeLimit } from '@utility/timeLimitHelpers';
import pendoInit from '@utility/pendoInit';
import { UserNamespace, RoleNumber, Role, isAfter2024, TemplateNamespace, FormNamespace } from './constants';
import { Action, Dispatch, GetState, PIITextResult, ServerOrganization, ServerUser, ThunkDeps, User, UserProfile, UserSettingsDialog } from './types';

// Initial State
export const INITIAL_STATE = {
  authenticated: 'unknown',
  loginError: false,
  loginDialogOpen: false,
  errorMessage: '',
  userSettingsDialog: {
    open: false,
    trainingMode: false,
    yearsOfExperience: false,
    theme: 'Device Theme',
    race: [],
  },
  user: {
    created_at: undefined,
    email: undefined,
    first_name: undefined,
    id: undefined,
    last_name: undefined,
    officer_id: undefined,
    organization_id: undefined,
    roles: null,
    updated_at: undefined,
    username: undefined,
    years_of_experience: 1,
    usage: undefined,
    training_mode: false,
    theme: 'Device Theme',
    profile: {
      race: [],
    },
    race: [],
  },
  organizationInfo: {
    name: '',
    small_logo: '',
    large_logo: '',
    login_background: '',
    auth_provider: '',
    can_submit_to_doj: false,
    pre2024: false,
    earlyPost2024: false,
    analytics_url: null,
  },
  piiTextRedactions: {},
};

// Action Constants
export const LOGIN_ERROR = `${UserNamespace}/LOGIN_ERROR`;
export const SET_AUTHENTICATED = `${UserNamespace}/SET_AUTHENTICATED`;
export const SET_USER = `${UserNamespace}/SET_USER`;
export const SET_ORGANIZATION_INFO = `${UserNamespace}/SET_ORGANIZATION_INFO`;
export const SET_LOGIN_DIALOG_OPEN = `${UserNamespace}/SET_LOGIN_DIALOG_OPEN`;
export const SET_LOGIN_ERROR = `${UserNamespace}/SET_LOGIN_ERROR`;
export const SET_USER_SETTINGS_DIALOG_OPEN = `${UserNamespace}/SET_USER_SETTINGS_DIALOG_OPEN`;
export const SET_TRAINING_MODE = `${UserNamespace}/SET_TRAINING_MODE`;
export const SET_YEARS_OF_EXPERIENCE = `${UserNamespace}/SET_YEARS_OF_EXPERIENCE`;
export const SET_RACE_OF_OFFICER = `${UserNamespace}/SET_RACE_OF_OFFICE`;
export const SET_PII_TEXT_REDACTION = `${UserNamespace}/SET_PII_TEXT_REDACTION`;
export const SET_THEME = `${UserNamespace}/SET_THEME`;

// Reducer
export const reducer = (state = INITIAL_STATE, action: Action<any>) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_ERROR:
      return assoc('loginError', true, state);
    case SET_AUTHENTICATED:
      return assoc('authenticated', payload.authenticated, state);
    case SET_USER: {
      localStorage.setItem('theme', payload.user?.theme ?? 'Device Theme');
      if (payload.user?.theme === 'Device Theme') {
        const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setDarkMode(isDark);
        detectThemeChange();
      } else {
        setDarkMode(payload.user?.theme === 'Dark');
      }
      pendoInit(payload.user?.id, payload.user?.email, payload.user?.organization_id, state.organizationInfo?.name);
      const { profile, ...user } = payload?.user;
      if (profile && 'gender' in profile) {
        delete profile.gender;
      }
      return assoc('user', { ...user, profile, roles: payload?.user?.roles ?? [] }, state);
    }
    case SET_ORGANIZATION_INFO: {
      return assoc('organizationInfo', payload, state);
    }
    case SET_LOGIN_DIALOG_OPEN: {
      return assoc('loginDialogOpen', payload.open, state);
    }
    case SET_LOGIN_ERROR: {
      return assoc('errorMessage', payload.errorMessage, state);
    }
    case SET_USER_SETTINGS_DIALOG_OPEN: {
      return {
        ...state,
        userSettingsDialog: {
          open: payload.open,
          yearsOfExperience: state.user.years_of_experience,
          trainingMode: state.user.training_mode,
          theme: state.user?.theme ?? 'Device Theme',
          raceOfOfficer: state.user?.race ? state.user.race : ['']
        },
      };
    }
    case SET_TRAINING_MODE: {
      return assocPath(['userSettingsDialog', 'trainingMode'], payload.isOn, state);
    }
    case SET_THEME: {
      return assocPath(['userSettingsDialog', 'theme'], payload.theme, state);
    }
    case SET_YEARS_OF_EXPERIENCE: {
      return assocPath(['userSettingsDialog', 'yearsOfExperience'], payload.yearsOfExperience, state);
    }

    case SET_RACE_OF_OFFICER: {
      return assocPath(['userSettingsDialog', 'raceOfOfficer'], payload.raceOfOfficer, state);
    }
    case SET_PII_TEXT_REDACTION: {
      const { text, matches, id } = payload;
      const updatedPIITextRedaction = {
        ...state.piiTextRedactions,
        [id]: {
          text,
          matches
        }
      };
      return assoc('piiTextRedactions', updatedPIITextRedaction, state);
    }
    default:
      return state;
  }
};

export const setUser = (user: any) => ({
  type: SET_USER,
  payload: { user },
});

export const loginError = () => ({
  type: LOGIN_ERROR,
  payload: {},
});

export const setAuthenticated = (authenticated: string, source?: string) => ({
  type: SET_AUTHENTICATED,
  payload: { authenticated, source },
});

export const setOrganizationInfo = (payload: any) => ({
  type: SET_ORGANIZATION_INFO,
  payload,
});

export const setPIITextRedaction = (payload: { text: string; matches: string[]; id: string}) => ({
  type: SET_PII_TEXT_REDACTION,
  payload,
});

export const setLoginDialogOpen = ({ open }: { open: boolean }) => ({
  type: SET_LOGIN_DIALOG_OPEN,
  payload: { open },
});

export const setLoginError = (errorMessage: string) => ({
  type: SET_LOGIN_ERROR,
  payload: { errorMessage },
});

export const setUserSettingsDialogOpen = ({ open }: { open: boolean }) => ({
  type: SET_USER_SETTINGS_DIALOG_OPEN,
  payload: { open },
});

export const setTrainingMode = (isOn: boolean) => ({
  type: SET_TRAINING_MODE,
  payload: { isOn },
});

export const setTheme = (theme: string) => ({
  type: SET_THEME,
  payload: { theme },
});

export const setYearsOfExperience = ({ yearsOfExperience }: { yearsOfExperience: number }) => ({
  type: SET_YEARS_OF_EXPERIENCE,
  payload: { yearsOfExperience },
});

export const setRaceOfOfficer = ({ raceOfOfficer }: { raceOfOfficer: string[] }) => ({
  type: SET_RACE_OF_OFFICER,
  payload: { raceOfOfficer },
});

export const workOnline =
  () =>
  (_: Dispatch<any>, __: GetState, { offlineConfig }: ThunkDeps) =>
    offlineConfig.setItem('work-offline', 'false').then(() => {
      if (!location.href.includes('log_in')) {
        setTimeout(() => assignRoute('/log_in'), 100);
      }
    });

export const workOffline =
  () =>
  (_: Dispatch<any>, __: GetState, { offlineConfig }: ThunkDeps) =>
    offlineConfig.setItem('work-offline', 'true').then(() =>
      setTimeout(() => {
        location.href = Paths.Dashboard.path;
      }, 500)
    );

export const logout =
  () =>
  (dispatch: Dispatch<any>, _: GetState, { http }: ThunkDeps) =>
    http
      .delete('/users/sign_out')
      .then(() => {
        dispatch(setUser({ roles: [], id: null }));
        dispatch(setAuthenticated('failure', 'signout'));
        assignRoute('/log_in');
      })
      .catch((err) => {
        if (err?.response?.status === 401 && err?.response?.data?.redirect_url) {
          assignRoute(err.response.data.redirect_url);
        } else {
          dispatch(setAuthenticated('failure', 'session'));
          dispatch(loginError());
        }
      });

export const login =
  ({ username, password, usingDialog }: { username: string; password: string; usingDialog?: any }) =>
  (dispatch: Dispatch<any>, _: GetState, { http, offlineConfig }: ThunkDeps) => {
    let orgId: number | undefined;

    return http
      .post<ServerUser>('/users/sign_in', {}, { Authorization: `Basic ${btoa(`${username?.trim()}:${password}`)}`, 'x-use-network': usingDialog })
      .then(async ({ data }) => {
        await offlineConfig.setItem('work-offline', 'false');
        await offlineConfig.setItem('user', data);
        localStorage.setItem('contact-is-online', 'true');
        decoupledDispatch('Config.checkOnlineStatus', true);
        dispatch(setUser(data));
        dispatch(setAuthenticated('success', usingDialog ? 'dialog' : 'login'));
        dispatch(setLoginError(''));
        orgId = data?.organization_id;
        clearTimeLimit()
        if (usingDialog) {
          dispatch(setLoginDialogOpen({ open: false }));
          // Sync the current offline form if we are currently working on a form while
          if (location.href.includes(Paths.NewReport.path.split('/')[1])) {
            decoupledDispatch('Form.syncCurrentOfflineForm');
          }
        }
      })
      .then(() => {
        if (!orgId) {
          throw new Error('No orgId returned');
        }

        return http
          .get<ServerOrganization>(`/organizations/${orgId}`)
          .then(({ data }) => {
            localStorage.setItem('contact-offline-mode-allowed', String(data.organization.offline_mode));
            dispatch(setOrganizationInfo(data.organization));
          })
          .catch(httpErrorHandler('Failed to get organization information'));
      })
      .catch((e) => {
        if (e?.message?.includes('Network Error')) {
          dispatch(setLoginError('No network connection'));
        }
        if (e?.message?.includes('401')) {
          dispatch(setLoginError('Username or password incorrect'));
        }
        dispatch(setAuthenticated('failure', 'login'));
        dispatch(loginError());
      });
  };

export const checkSession =
  (param?: any) =>
  (dispatch: Dispatch<any>, _: GetState, { http, offlineConfig }: ThunkDeps) => {
    const { forceOnline, forceStorage } = param ?? {};
    let orgId: number | undefined;

    return http
      .get<ServerUser>('/users/session', { 'x-use-network': !!forceOnline, 'x-use-storage': !!forceStorage }, { timeout: getOnlineTimeoutByEffectiveType() })
      .then(async ({ data }) => {
        await offlineConfig.setItem('work-offline', 'false');
        await offlineConfig.setItem('user', data);
        dispatch(setUser(data));
        dispatch(setAuthenticated('success', 'session'));
        orgId = data?.organization_id;
        const isNewReportPage = location.href.includes(Paths.NewReport.path.split('/')[1]);
        localStorage.setItem('sentry-user', JSON.stringify(data));

        if (forceOnline && isNewReportPage) {
          decoupledDispatch('Form.syncCurrentOfflineForm');
        }
        clearTimeLimit()
      })
      .then(() =>
        http
          .get<ServerOrganization>(`/organizations/${orgId}`)
          .then(({ data }) => {
            localStorage.setItem('contact-offline-mode-allowed', String(data.organization.offline_mode));
            dispatch(setOrganizationInfo(data.organization));
          })
          .catch(httpErrorHandler('Failed to get organization information'))
      )
      .catch((err) => {
        if (err?.response?.data?.organization_id) {
          // Service worker returns cached user on 401
          dispatch(setUser(err.response.data));
          dispatch(setOrganizationInfo(err.response.data.organization));
        }

        // only saml based authentication (OneLogin, Azure AD) will have redirect_url
        if (err?.response?.status === 401 && err?.response?.data?.redirect_url) {
          assignRoute(err.response.data.redirect_url);
        } else {
          dispatch(setAuthenticated('failure', 'session'));
        }
      });
  };

export const getOrganization = (withCredentials = true) =>
  (dispatch: Dispatch<any>, _: GetState, { http }: ThunkDeps) =>
    http
      .get<ServerOrganization>('/organization', { 'x-no-cred': `${!withCredentials}` }, { withCredentials })
      .then(({ data }) => {
        dispatch(setOrganizationInfo(data.organization));
        const offlineModeAllowed = localStorage.getItem('contact-offline-mode-allowed');
        localStorage.setItem('contact-offline-mode-allowed', String(data.organization.offline_mode));
        if (!offlineModeAllowed && data.organization.offline_mode) {
          window.location.reload();
        }
      })
      .catch((e) => {
        if (e?.message?.includes('404')) {
          dispatch(setLoginError("You're attempting to connect to an invalid organization, please contact your system administrator"));
          dispatch(loginError());
        }
      });

export const saveUserSettings =
  () =>
  (dispatch: Dispatch<any>, getState: GetState, { http }: ThunkDeps) => {
    const {
      userSettingsDialog,
      user: { id },
    } = getState()[UserNamespace];
    return http
      .patch(`/users/${id}`, {
        training_mode: userSettingsDialog.trainingMode,
        years_of_experience: userSettingsDialog.yearsOfExperience,
        theme: userSettingsDialog.theme,
        profile: { race: userSettingsDialog.raceOfOfficer },
      })
      .then(() => {
        const { user } = getState()[UserNamespace];
        dispatch(
          setUser({
            ...user,
            training_mode: userSettingsDialog.trainingMode,
            years_of_experience: userSettingsDialog.yearsOfExperience,
            theme: userSettingsDialog.theme,
            race: userSettingsDialog.raceOfOfficer,
            profile: { race: userSettingsDialog.raceOfOfficer },
          })
        );
        dispatch(setUserSettingsDialogOpen({ open: false }));
        createSnackNotification(AlertLevel.Success, 'Success', 'User settings updated');
      })
      .catch(httpErrorHandler('Failed to update user settings'));
  };

export const updateYearsOfExp =
  ({ yearsOfExperience }: { yearsOfExperience: number }) =>
  (_: Dispatch<any>, getState: GetState, { http }: ThunkDeps) => {
    const { id } = getState()[UserNamespace].user;

    return http.patch(`/users/${id}`, { years_of_experience: yearsOfExperience }).catch(httpErrorHandler('Failed to update user'));
  };

export const sendUserEmail =
  ({ email }: { email: string }) =>
  (_: Dispatch<any>, __: GetState, { http }: ThunkDeps) =>
    http.post('/users/password', { user: { email } }).catch(() => console.error('Failed to send email'));

export const sendUserUsername =
  ({ username }: { username: string }) =>
  (_: Dispatch<any>, __: GetState, { http }: ThunkDeps) =>
    http.post('/users/password', { user: { username } }).catch(() => console.error('Failed to send username'));

export const changePassword =
  ({ password, password_confirmation }: { password: string; password_confirmation: string }) =>
  (_: Dispatch<any>, __: GetState, { http }: ThunkDeps) => {
    const reset_password_token = location.search.split('=')[1];
    return http
      .patch('/users/password', { user: { reset_password_token, password, password_confirmation } })
      .then(() => {
        createSnackNotification(AlertLevel.Success, 'Success', 'Password has successfully been changed');
        setTimeout(() => assignRoute('/log_in'), 3000);
      })
      .catch(() => {
        createSnackNotification(AlertLevel.Error, 'Error', 'Failed to update password');
      });
  };

export const getPIIRedactionsFromText = (textToRedact: string, id: string) => async (dispatch: Dispatch<any>, _getState: GetState, { http }: ThunkDeps) => {
  if (textToRedact) {
    const query = qs.stringify({
      textChars: textToRedact,
      relation: 'mine'
    }, { arrayFormat: 'brackets' })
    return http
      .get<{text: string; matches: string[]}>(`/form_pii_redaction?${query}`, { 'x-use-storage': true })
      .then(({ data }) => {
        dispatch(setPIITextRedaction({ text: data.text, matches: data.matches, id }));
      }).catch(() => {
        console.error('Failed to get pii redactions, using default redaction');
        dispatch(setPIITextRedaction({ text: textToRedact, matches: [], id }));
      })
  }
  dispatch(setPIITextRedaction({ text: '', matches: [], id }));
}

const getRolesHelper = (roles: string[] | undefined) =>
  (!roles ? null : map((r: string) => get(RoleNumber, [r]))(roles))
const customKeyGenerator = (roles: string[] | undefined) => roles ? roles.join('') : '';

const memoizedGetRolesHelper = memoizeWith(customKeyGenerator, getRolesHelper)

// State Selectors
export const selectors = {
  state: path<keyof typeof stateBounds>([UserNamespace, 'user', 'organization', 'state']),
  loginError: path([UserNamespace, 'loginError']),
  loginDialogOpen: pathOr<boolean>(false, [UserNamespace, 'loginDialogOpen']),
  userSettingsDialog: path<UserSettingsDialog>([UserNamespace, 'userSettingsDialog']),
  trainingMode: path([UserNamespace, 'user', 'training_mode']),
  errorMessage: path<string>([UserNamespace, 'errorMessage']),
  authenticated: path([UserNamespace, 'authenticated']),
  smallLogo: path<string>([UserNamespace, 'organizationInfo', 'small_logo']),
  largeLogo: path<string>([UserNamespace, 'organizationInfo', 'large_logo']),
  loginBackground: path<string>([UserNamespace, 'organizationInfo', 'login_background']),
  authProvider: path<string>([UserNamespace, 'organizationInfo', 'auth_provider']),
  canSubmitToDOJ: path([UserNamespace, 'organizationInfo', 'can_submit_to_doj']),
  roles: compose((roles: string[] | undefined) => memoizedGetRolesHelper(roles), path([UserNamespace, 'user', 'roles'])),
  userId: path<number>([UserNamespace, 'user', 'id']),
  isUserSettingsValid: compose((state: any) => {
    const { userSettingsRequiredFields } = env().REACT_APP_FEATURE_FLAGS;
    const user = path<User>([UserNamespace, 'user'], state);
    const assignedRoles = getRolesHelper(user?.roles);
    const pre2024 = pathOr(false, [UserNamespace, 'organizationInfo', 'pre2024'], state);
    const earlyPost2024 = pathOr(false, [UserNamespace, 'organizationInfo', 'earlyPost2024'], state);
    const templateName = path<string>([TemplateNamespace, 'name'], state);
    const stopDateTime = pathOr<string>('', [FormNamespace, 'stopDateTime'], state);

    const isPost2024 = () => earlyPost2024 || pre2024 || isAfter2024(stopDateTime, templateName);

    // these user setting validations are for 2024 changes return true if not 2024
    if (isPost2024() === false) {
      return true;
    }

    if (!user || !user.id) {
      return false;
    }

    const isUserFieldValid = (field: string): boolean => {
      switch (field) {
        case 'race':
          return (!!user.race && (user.race?.length > 0 || !assignedRoles?.includes(Role.Officer)));
        default:
          return true;
      }
    }

    if (userSettingsRequiredFields) {
      return allPass(userSettingsRequiredFields.map((field: string) => () => isUserFieldValid(field)))(user);
    }
    return true;
  }),
  organizationId: path([UserNamespace, 'user', 'organization_id']),
  piiTextRedactions: path<PIITextResult>([UserNamespace, 'piiTextRedactions']),
  raceOfOfficer: path<string[]>([UserNamespace, 'user', 'race']),
  profile: path<UserProfile>([UserNamespace, 'user', 'profile']),
  pre2024: pathOr(false, [UserNamespace, 'organizationInfo', 'pre2024']),
  earlyPost2024: pathOr(false, [UserNamespace, 'organizationInfo', 'earlyPost2024']),
  testingBanner: pathOr(false, [UserNamespace, 'organizationInfo', 'testingBanner']),
  clipboardCodeFeature: pathOr(false, [UserNamespace, 'organizationInfo', 'clipboardCodeFeature']),
  enforceOfficerDemographics: pathOr(true, [UserNamespace, 'organizationInfo', 'enforceOfficerDemographics']),
  defaultTemplateName: pathOr(false, [UserNamespace, 'organizationInfo', 'defaultTemplateName']),
  analyticsUrl: pathOr(null, [UserNamespace, 'organizationInfo', 'analytics_url']),
};

export default reducer;
