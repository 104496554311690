/* eslint-disable */

import React, { useState, useEffect, useRef, useCallback } from "react";
import cn from "classnames";
import { range, remove, insert } from "ramda";
import { throttle } from "lodash";
import dayjs from "dayjs";
import { Tooltip } from "@material-ui/core";
import { Button, Dialog } from "@components/common";
import { Status, FormNamespace } from "@ducks/constants";
import { selectors as FormSelectors } from '@ducks/form';
import { onEnter } from "@utility/keypressHelpers";
import { Props, connector } from './props';
import "./ReviewDialog.scss"
// import { isSuspicionCodeRequired } from "@utility/offenseCode";

const ReviewDialog = ({
  userForms,
  reviewDialogId,
  reviewDialogOpen,
  closeReviewDialog,
  approveReport,
  removeReport,
  activateReport,
  setReviewFeedbackDialogOpen,
  addReviewTimeSpent,
  userId,
  customQuestions,
}: Props) => {
  const [accordionOpen, setAccordionOpen] = useState<number[]>([0])
  const form = userForms.find((f: any) => Number(f.id) === Number(reviewDialogId))
  const {
    numberOfPeople,
    city,
    stopDateTime,
    duration,
    useSameActionTakenForAll,
    person,
    school,
    useSameActionForAllPersonIndex,
    useSameRaceForAll,
    useSameDisabilityForAll,
    useSameGenderForAll,
    useSamePrimaryReasonForAll,
    custom,
    officerWorksWithNonPrimaryAgency,
    stopDuringWellnessCheck,
    typeOfAssignmentOfficer,
    assignment,
    assignmentDescription,
    typeOfStop,
    stopHappenedAtPublicSchool,
    flags: { pre2024, post2024 } = { pre2024: false, post2024: false },
  } = form?.contents ?? {}
  const locationString = FormSelectors.locationString({ [FormNamespace]: form?.contents });

  const { created_at, id } = form ?? {}
  const { status, deleted_at } = userForms.find((f: any) => Number(f.id) === Number(reviewDialogId)) ?? {}
  const resultPathMap = {
    ...customQuestions?.all?.questions.reduce(
      (acc: object, q: any) => (q?.props && !(q?.disabled && dayjs(q?.disabledDate).diff(dayjs(created_at)) < 0) ? { ...acc, [q?.props?.resultPath]: q?.props?.title } : acc),
      {}
    ),
    ...customQuestions?.individual?.questions.reduce(
      (acc: object, q: any) => (q?.props && !(q?.disabled && dayjs(q?.disabledDate).diff(dayjs(created_at)) < 0) ? { ...acc, [q?.props?.resultPath]: q?.props?.title } : acc),
      {}
    ),
  }

  const toggleAccordion = (i: number) => {
    const ai = accordionOpen.findIndex((a) => a === i)
    if (ai === -1) {
      setAccordionOpen(insert(0, i, accordionOpen))
    } else {
      setAccordionOpen(remove(ai, 1, accordionOpen))
    }
  }

  const maxInactivity = 20000
  const userActivityEvents = ["keydown", "mousedown", "mousemove", "scroll", "touchstart"]
  const dialogContent = useRef<HTMLDivElement>(null)
  const millsSinceLastActivity = useRef(0)
  const lastActivityTime = useRef(Date.now())

  const userActivityCbs = userActivityEvents.reduce(
    (acc, event) => ({
      ...acc,
      [event]: useCallback(
        throttle(
          () => {
            millsSinceLastActivity.current = Date.now() - lastActivityTime.current
            if (millsSinceLastActivity.current < maxInactivity) {
              addReviewTimeSpent({ id: reviewDialogId, addTimeSec: millsSinceLastActivity.current / 1000, reviewerId: userId })
            }
            lastActivityTime.current = Date.now()
          },
          2000,
          { trailing: false, leading: true }
        ),
        [event]
      ),
    }),
    []
  ) as any;

  useEffect(() => {
    if (reviewDialogOpen) {
      userActivityEvents.forEach((event) => {
        if (dialogContent.current) {
          dialogContent.current.addEventListener(event, userActivityCbs[event]);
        }
      })
    }
  }, [reviewDialogOpen])

  return (
    <Dialog open={reviewDialogOpen} onClickOff={closeReviewDialog}>
      <div className="review-dialog" data-testid="review-dialog" ref={dialogContent}>
        <div
          className="review-dialog__close material-icons"
          data-testid="review-dialog-close"
          role="button"
          aria-label="Close review dialog"
          tabIndex={0}
          onClick={closeReviewDialog}
          onKeyUp={onEnter(closeReviewDialog)}
        >
          close
        </div>
        <div className="review-dialog__title">Review Report</div>
        <div className="review-dialog__content">
          <div className="review-dialog__content-primary-info">
            <div className="review-dialog__content-primary-info-tables">
              <div className="review-dialog__left">
                <div className="review-dialog__left-row">
                  <div className="review-dialog__left-cell icon">
                    <span className="material-icons">description</span>
                  </div>
                  <div className="review-dialog__left-cell key">Stop ID:</div>
                  <div className="review-dialog__left-cell value" data-testid="review-dialog-formid">{id}</div>
                </div>
                <div className="review-dialog__left-row">
                  <div className="review-dialog__left-cell icon">
                    <span className="material-icons">person</span>
                  </div>
                  <div className="review-dialog__left-cell key">People:</div>
                  <div className="review-dialog__left-cell value">{numberOfPeople}</div>
                </div>
                <div className="review-dialog__left-row">
                  <div className="review-dialog__left-cell icon">
                    <span className="material-icons">place</span>
                  </div>
                  <div className="review-dialog__left-cell key">Location:</div>
                  <div className="review-dialog__left-cell value">
                    <span>{`${locationString || "Unknown"}, ${city || "Unknown"}`}</span>
                  </div>
                </div>
              </div>
              <div className="review-dialog__right">
                <div className="review-dialog__right-row">
                  <div className="review-dialog__right-cell icon">
                    <span className="material-icons">calendar_today</span>
                  </div>
                  <div className="review-dialog__right-cell key">Date:</div>
                  <div className="review-dialog__right-cell value">{dayjs(stopDateTime).format("MM/DD/YYYY")}</div>
                </div>

                <div className="review-dialog__right-row">
                  <div className="review-dialog__right-cell icon">
                    <span className="material-icons">access_time</span>
                  </div>
                  <div className="review-dialog__right-cell key">Time:</div>
                  <div className="review-dialog__right-cell value">{dayjs(stopDateTime).format("hh:mm A")}</div>
                </div>
                <div className="review-dialog__right-row">
                  <div className="review-dialog__right-cell icon">
                    <span className="material-icons">timer</span>
                  </div>
                  <div className="review-dialog__right-cell key">Duration:</div>
                  <div className="review-dialog__right-cell value">{`${duration} minutes`}</div>
                </div>
              </div>
            </div>
            <div className="review-dialog__content-primary-info-cc">
              {custom && (
                <div className="review-dialog__per-stop-custom-questions">
                  {Object.entries(custom).map(([key, value]) => {
                    if (resultPathMap[key] && key !== "person") {
                      return (
                        <div className="review-dialog__per-stop-custom" key={`ReviewFormPerStopCustomQuestion-${key}`}>
                          <div className="review-dialog__per-stop-custom-icon material-icons">help</div>
                          <div className="review-dialog__per-stop-custom-text">
                            {resultPathMap?.[key] ||
                              key
                                .replace(/([a-z])([A-Z])/g, "$1 $2")
                                .replace(/([A-Z])([A-Z])/g, "$1 $2")
                                .replace(/<.+>/, "")
                                .toLowerCase()
                                .toTitleCase()}
                          </div>
                          <div className="review-dialog__per-stop-custom-value">
                            {Array.isArray(value) ? value.join(', ') : value || 'N/A'}
                          </div>
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
              )}
            </div>
          </div>
          {range(0, numberOfPeople ?? 0).map((pi) => {
            const { label, age } = person[pi]
            const gender = person?.[useSameGenderForAll ? 0 : pi].gender
            const race = person?.[useSameRaceForAll ? 0 : pi].race
            const disabilities = person?.[useSameDisabilityForAll ? 0 : pi].disabilities
            const { lgbt } = gender?.startsWith("Trans") ? { lgbt: true } : person?.[useSameDisabilityForAll ? 0 : pi]
            const { sexualOrientation } = person?.[useSameDisabilityForAll ? 0 : pi]
            const { english } = person?.[useSameDisabilityForAll ? 0 : pi]
            const { disabled } = person?.[useSameDisabilityForAll ? 0 : pi]
            const { unhoused } = person?.[useSameDisabilityForAll ? 0 : pi]
            const actionIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : pi
            const actionTaken = person?.[actionIndex]?.actionTaken
            const consentType = person?.[actionIndex]?.consentType
            const searchBasis = person?.[actionIndex]?.searchBasis
            const searchDescription = person?.[actionIndex]?.searchDescription
            const seizedPropertyBasis = person?.[actionIndex]?.seizedPropertyBasis
            const seizedPropertyType = person?.[actionIndex]?.seizedPropertyType
            const contraband = person?.[actionIndex]?.anyContrabandFound ? person?.[actionIndex]?.contraband : []
            const resultOfStop = person?.[actionIndex]?.anyResultOfStop ? person?.[actionIndex]?.resultOfStop : []
            const resultOfStopCodeSection = person?.[actionIndex]?.anyResultOfStop ? person?.[actionIndex]?.resultOfStopCodeSection : {}

            const reasonIndex = useSamePrimaryReasonForAll ? 0 : pi
            const reasonGivenStoppedPerson = person?.[actionIndex]?.reasonGivenStoppedPerson
            const stoppedPassenger = person?.[pi]?.stoppedPassenger
            const stoppedInsideResidence = person?.[reasonIndex]?.stoppedInsideResidence
            const stopDescription = person?.[reasonIndex]?.stopDescription
            const stopReason = person?.[reasonIndex]?.primaryReason
            const reasonableSuspicionCode = person?.[reasonIndex]?.reasonableSuspicionCode
            const probableCause = person?.[reasonIndex]?.probableCause;
            const probableCauseCode = person?.[reasonIndex]?.probableCauseCode;
            const unknownGender = 'Gender Non-Conforming';

            return (
              <div className="review-dialog__accordion" key={`RipaReviewForm-Accordion-${pi}`}>
                <div className="review-dialog__accordion-header" role="button" tabIndex={0} onClick={() => toggleAccordion(pi)} onKeyUp={onEnter(() => toggleAccordion(pi))}>
                  <div className="review-dialog__accordion-header-label">
                    <div className="material-icons">person_outline</div>
                    <div className="review-dialog__accordion-header-label-text" data-testid={`review-dialog-accordian-header-label-${pi}`}>{label || pi + 1}</div>
                  </div>
                  <div className="review-dialog__accordion-header-controls">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => toggleAccordion(pi)}
                      onKeyUp={onEnter(() => toggleAccordion(pi))}
                      data-testid={`review-dialog-accordion-header-controls-expand-${pi}`}
                      className={cn("review-dialog__accordion-header-controls-expand material-icons", { open: accordionOpen.includes(pi) })}
                    >
                      expand_less
                    </div>
                  </div>
                </div>

                <div className={cn("review-dialog__accordion-content", { open: accordionOpen.includes(pi) })}>
                  <div className="review-dialog__accordion-content-top">
                    <div className="review-dialog__accordion-content-left">
                      {pre2024 && (
                        <>
                          <div className="review-dialog__accordion-content-row">
                            <div className="review-dialog__accordion-content-key">Type of Assignment:</div>
                            <div className="review-dialog__accordion-content-value">{typeOfAssignmentOfficer}</div>
                          </div>
                          <div className="review-dialog__accordion-content-row">
                            <div className="review-dialog__accordion-content-key">Assignment:</div>
                            <div className="review-dialog__accordion-content-value">{assignment}</div>
                          </div>
                          {(assignmentDescription ?? '').length > 0 && (
                            <div className="review-dialog__accordion-content-row">
                              <div className="review-dialog__accordion-content-key">Description of assignment:</div>
                              <div className="review-dialog__accordion-content-value">{assignmentDescription}</div>
                            </div>
                          )}
                        </>
                      )}
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Gender:</div>
                        <div className="review-dialog__accordion-content-value">{gender || unknownGender}</div>
                      </div>
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Age:</div>
                        <div className="review-dialog__accordion-content-value">{age}</div>
                      </div>
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Ethnicity:</div>
                        <div className="review-dialog__accordion-content-value">{race?.join(", ") || "N/A"}</div>
                      </div>
                      {post2024 ? (
                        <div className="review-dialog__accordion-content-row">
                          <div className="review-dialog__accordion-content-key">Perceived Sexual Orientation:</div>
                          <div className="review-dialog__accordion-content-value">{sexualOrientation}</div>
                        </div>
                      ) : (
                        <div className="review-dialog__accordion-content-row">
                          <div className="review-dialog__accordion-content-key">LGBT:</div>
                          <div className="review-dialog__accordion-content-value">{lgbt ? "Yes" : "No"}</div>
                        </div>
                      )}
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Limited English Fluency:</div>
                        <div className="review-dialog__accordion-content-value">{english ? "Yes" : "No"}</div>
                      </div>
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Disability:</div>
                        <div className="review-dialog__accordion-content-value" data-testid={`review-dialog-accordion-content-disabilities-${pi}`}>{disabled && disabilities?.length > 0 ? disabilities.join(", ") : "None"}</div>
                      </div>
                      {pre2024 && (
                        <>
                          <div className="review-dialog__accordion-content-row">
                            <div className="review-dialog__accordion-content-key">Perceived to be Unhoused:</div>
                            <div className="review-dialog__accordion-content-value">{unhoused ? "Yes" : "No"}</div>
                          </div>
                          <div className="review-dialog__accordion-content-row">
                            <div className="review-dialog__accordion-content-key">Officer Works With Non Primary Agency:</div>
                            <div className="review-dialog__accordion-content-value">{officerWorksWithNonPrimaryAgency ? "True" : "False"}</div>
                          </div>
                          <div className="review-dialog__accordion-content-row">
                            <div className="review-dialog__accordion-content-key">Stop During Wellness Check:</div>
                            <div className="review-dialog__accordion-content-value">{stopDuringWellnessCheck ? "Yes" : "No"}</div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="review-dialog__accordion-content-right">
                      {stopHappenedAtPublicSchool && (
                        <div className="review-dialog__accordion-content-row">
                          <div className="review-dialog__accordion-content-key">Name of School:</div>
                          <div className="review-dialog__accordion-content-value" data-testid={`review-dialog-accordion-content-school-${pi}`}>{school || "N/A"}</div>
                        </div>
                      )}
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Actions:</div>
                        <div className="review-dialog__accordion-content-value" data-testid={`review-dialog-accordion-content-actions-taken-${pi}`}>{actionTaken?.map((r: any) => r?.replaceAll(/<[^>]*>/g, ""))?.join(", ") || "N/A"}</div>
                      </div>
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Basis For Search:</div>
                        <div className="review-dialog__accordion-content-value" data-testid={`review-dialog-accordion-content-search-basis-${pi}`}>{searchBasis?.join(", ") || "N/A"}</div>
                      </div>
                      {pre2024 && searchBasis.includes('Consent given') && (
                        <>
                          <div className="review-dialog__accordion-content-row">
                            <div className="review-dialog__accordion-content-key">Basis for Search Consent Type:</div>
                            <div className="review-dialog__accordion-content-value" data-testid={`review-dialog-accordion-content-consent-type-${pi}`}>{consentType}</div>
                          </div>
                        </>
                      )}
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Description Of Search:</div>
                        <div className="review-dialog__accordion-content-value">{searchDescription || "N/A"}</div>
                      </div>
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Basis For Property Seizure</div>
                        <div className="review-dialog__accordion-content-value" data-testid={`review-dialog-accordion-content-seized-property-basis-${pi}`}>{seizedPropertyBasis?.join(", ") || "N/A"}</div>
                      </div>
                      <div className="review-dialog__accordion-content-row">
                        <div className="review-dialog__accordion-content-key">Type Of Property Seizure</div>
                        <div className="review-dialog__accordion-content-value" data-testid={`review-dialog-accordion-content-seized-property-type-${pi}`}>{seizedPropertyType?.join(", ") || "N/A"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="review-dialog__accordion-divider" />
                  <div className="review-dialog__accordion-content-bottom">
                    {pre2024 && (
                      <div className="review-dialog__accordion-content-bottom-row">
                        <div className="review-dialog__accordion-content-bottom-cell">Type of Stop:</div>
                        <div className="review-dialog__accordion-content-bottom-cell">{typeOfStop}</div>
                      </div>
                    )}
                    {stopReason && (
                      <div className="review-dialog__accordion-content-bottom-row">
                        <div className="review-dialog__accordion-content-bottom-cell">Primary Reason For Stop:</div>
                        <div className="review-dialog__accordion-content-bottom-cell" data-testid={`review-dialog-accordion-content-stop-reason-${pi}`}>
                        <div>{`${stopReason} ${reasonableSuspicionCode !== "" ? `(${reasonableSuspicionCode.split(' - ')?.[0]})` : ""}`}</div>
                          {/* {(stopReason === ReasonsForStop.ReasonableSuspicion.value && reasonableSuspicionCode === "" && isSuspicionCodeRequired(stopDateTime)) && <div>
                            <Alert>Suspicion Offense Code is missing! It has been required since January 1, 2025.</Alert>
                          </div>} */}
                        </div>
                      </div>
                    )}
                    {pre2024 && stopReason && stopReason?.includes('Probable cause to arrest or search') && (
                      <>
                        <div className="review-dialog__accordion-content-bottom-row">
                          <div className="review-dialog__accordion-content-bottom-cell">Probable Cause:</div>
                          <div className="review-dialog__accordion-content-bottom-cell" data-testid={`review-dialog-accordion-content-probable-cause-${pi}`}>{probableCause}</div>
                        </div>
                        <div className="review-dialog__accordion-content-bottom-row">
                          <div className="review-dialog__accordion-content-bottom-cell">Probable Cause Code:</div>
                          <div className="review-dialog__accordion-content-bottom-cell" data-testid={`review-dialog-accordion-content-probable-cause-code-${pi}`}>{probableCauseCode}</div>
                        </div>
                      </>
                    )}
                    {pre2024 && (
                      <div className="review-dialog__accordion-content-bottom-row">
                        <div className="review-dialog__accordion-content-bottom-cell">Stopped person was a passenger:</div>
                        <div className="review-dialog__accordion-content-bottom-cell" data-testid={`review-dialog-accordion-content-stopped-passenger-${pi}`}>{stoppedPassenger ? "Yes" : "No"}</div>
                      </div>
                    )}
                    {pre2024 && (
                      <div className="review-dialog__accordion-content-bottom-row">
                        <div className="review-dialog__accordion-content-bottom-cell">Stopped person was inside a residence:</div>
                        <div className="review-dialog__accordion-content-bottom-cell">{stoppedInsideResidence ? "Yes" : "No"}</div>
                      </div>
                    )}
                    {pre2024 && (
                      <div className="review-dialog__accordion-content-bottom-row">
                        <div className="review-dialog__accordion-content-bottom-cell">Reason Given to Stopped Person:</div>
                        <div className="review-dialog__accordion-content-bottom-cell">{reasonGivenStoppedPerson?.join(',')}</div>
                      </div>
                    )}
                    {stopDescription && (
                      <div className="review-dialog__accordion-content-bottom-row">
                        <div className="review-dialog__accordion-content-bottom-cell">Description of Stop:</div>
                        <div className="review-dialog__accordion-content-bottom-cell">{stopDescription}</div>
                      </div>
                    )}
                    <div className="review-dialog__accordion-content-bottom-row">
                      <div className="review-dialog__accordion-content-bottom-cell">Contraband or Evidence Discovered:</div>
                      <div className="review-dialog__accordion-content-bottom-cell">{contraband?.join(", ") || "None"}</div>
                    </div>
                    <div className="review-dialog__accordion-content-bottom-row">
                      <div className="review-dialog__accordion-content-bottom-cell">Result of Stop:</div>
                      <div className="review-dialog__accordion-content-bottom-cell" data-testid={`review-dialog-accordion-content-result-of-stop-value-${pi}`}>
                        {resultOfStop
                          ?.map((r: any) =>
                            resultOfStopCodeSection?.[r]
                              ? `${r} (${Array.isArray(resultOfStopCodeSection?.[r]) ? resultOfStopCodeSection?.[r]?.map((code: string) => code.split(" - ")?.[0]) : resultOfStopCodeSection?.[r]?.split(" - ")?.[0]
                              })`
                              : r
                          )
                          ?.map((r: any) => r?.replaceAll(/<[^>]*>/g, ""))
                          .join(", ") || "N/A"}
                      </div>
                    </div>
                    {Object.entries(custom?.person?.[pi] ?? {}).map(([key, value]) => {
                      if (resultPathMap[key]) {
                        return (
                          <div className="review-dialog__accordion-content-bottom-row">
                            <div className="review-dialog__accordion-content-bottom-cell">
                              {resultPathMap?.[key] ||
                                key
                                  .replace(/([a-z])([A-Z])/g, "$1 $2")
                                  .replace(/([A-Z])([A-Z])/g, "$1 $2")
                                  .replace(/<.+>/, "")}
                            </div>
                            <div className="review-dialog__accordion-content-bottom-cell">
                              {Array.isArray(value) ? value.join(', ') : value || 'N/A'}
                            </div>
                          </div>
                        )
                      }
                      return <></>
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="review-dialog__buttons">
          <div className="review-dialog__remove-buttons">
            {!deleted_at && status !== Status.SubmittedToDOJ && (
              <Tooltip title="Removing reports will remove them from the Officer's view but can be reviewed using the Removed Reports filter.">
                <Button
                  className="review-dialog__remove-report-btn"
                  priority="primary"
                  aria-label="Remove the Report"
                  data-testid="review-dialog-remove-report-btn"
                  materialIcon="remove"
                  onClick={() => removeReport({ id: reviewDialogId })}
                >
                  Remove Report
                </Button>
              </Tooltip>
            )}
            {deleted_at && (
              <Button
                className="review-dialog__approve-deny-btn-activate"
                priority="primary"
                aria-label="Activate the Report"
                data-testid="review-dialog-activate-bottom"
                materialIcon="add"
                onClick={() => activateReport({ id: reviewDialogId })}
              >
                Activate Report
              </Button>
            )}
          </div>
          <div className="review-dialog__approve-deny-buttons">
            {![Status.Approved, Status.Rejected, Status.Draft, Status.SubmittedToDOJ, Status.SubmittingToDOJ].includes(status) && !deleted_at && (
              <Button
                className="review-dialog__approve-deny-btn-deny"
                data-testid="review-dialog-deny-bottom"
                priority="secondary"
                aria-label="Deny this report"
                onClick={() => {
                  closeReviewDialog()
                  setReviewFeedbackDialogOpen({ open: true, formId: reviewDialogId, isReadOnly: undefined })
                }}
              >
                <div className="material-icons review-dialog__cancel-icon">clear</div>
                Reject Report
              </Button>
            )}
            {status === Status.UnderReview && !deleted_at && (
              <Button
                className="review-dialog__approve-deny-btn-approve"
                priority="primary"
                aria-label="Approve this report"
                data-testid="review-dialog-approve-bottom"
                onClick={() => approveReport({ id: reviewDialogId })}
              >
                <div className="material-icons review-dialog__done-icon">done</div>
                Approve Report
              </Button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default connector(ReviewDialog)
