import { AlertLevel, Button, Column, ConfirmDialog, Table, createSnackNotification } from '@components/common';
import { Header, MoreDashboardActions, ReportRow, TestModeBanner, TrainingModeBanner } from '@components/custom';
import { Role, Status, StatusString } from '@ducks/constants';
import { ReviewFeedbackNote } from '@ducks/types';
import { offlineConfig } from '@engine/dependencies/localforage';
import { FormControl, InputAdornment, LinearProgress, MenuItem, Select, TextField } from '@material-ui/core';
import { BREAKPOINTS, mobileCheck } from '@utility/breakpoint';
import { decoupledDispatch } from '@utility/decoupledDispatch';
import { onEnter } from '@utility/keypressHelpers';
import { sortNumber, sortText, sortTime } from '@utility/sort';
import { counterEffect } from '@utility/timeLimitHelpers';
import { userSettingsErrorMessage } from '@utility/userSettingsErrorMessage';
import cn from 'classnames';
import dayjs from 'dayjs';
import noElasticScroll from 'inobounce';
import { debounce, throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Dashboard.scss';
import { Props, composer } from './props';

const throttledGetUserForms = throttle((params) => decoupledDispatch('Dashboard.getUserForms', params), 1000, { trailing: false, leading: true });

export const Dashboard = ({
  assignedRoles,
  assignment,
  setAssignment,
  otherAssignment,
  setOtherAssignment,
  officerAssignment,
  breakpoint,
  searchAndFilter,
  reportData,
  createNewForm,
  getUserForms,
  getFormTemplate,
  pagination,
  deleteForm,
  setPagination,
  toggleFilterAndSearch,
  setSort,
  setSearch,
  loading,
  online,
  statistics,
  getStats,
  reviewFeedbackDialogContent,
  reviewFeedbackDialogOpen,
  setReviewFeedbackDialogOpen,
  syncOfflineForms,
  showOffline,
  hasTemplate,
  getOrgCustomQuestions,
  trainingMode,
  testingBanner,
  isUserSettingsValid,
  counter,
  intervalId,
  workOnline,
}: Props) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const otherAssignmentForm = useRef<HTMLElement>(null);
  const officerAssignmentVal = officerAssignment?.OfficerTypeofAssignment?.possibleValues;
  const otherAssignmentValue = otherAssignment === '' ? localStorage.getItem('otherAssignmentValue') : otherAssignment;
  const { filterSelected, sort } = searchAndFilter;
  const [isWorkingOffline, setIsWorkingOffline] = useState(true);
  const [showAllComments, setShowAllComments] = useState(false);
  const [exceedTimeLimit, setExceedTimeLimit] = useState(false);
  const toggleShowAllComments = () => {
    setShowAllComments(!showAllComments);
  }

  const paginationThrottled = useCallback(throttle((newPagination) => {
    const { currentPage, totalPages } = newPagination;
    if (currentPage > totalPages) {
      setPagination({ ...pagination, currentPage: 1 })
    }
  }, 1000), []);

  // Only allow training mode online
  trainingMode = isWorkingOffline ? false : trainingMode;
  const statisticsTraining = {};
  const { last24HourForms, draftStatus, approvedStatus, underReviewStatus, rejectedStatus, offlineForms } = trainingMode ? statisticsTraining : statistics;

  useEffect(() => {
    // IndexedDB observers are currently experimental
    const runSetIsWorkingOffline = async () => {
      const workOfflineStatus = await offlineConfig.getItem('work-offline').then((wo) => String(wo) === 'true');
      setIsWorkingOffline(workOfflineStatus);
    };
    runSetIsWorkingOffline();
  }, [online]);

  const fetchMore = useCallback(
    debounce((event) => {
      if (event?.target) {
        const { scrollHeight: sh, scrollTop: st, clientHeight: ch } = event.target;
        if (sh - st - ch < 10) {
          const { currentPage, pageSize, totalPages } = pagination;
          if (currentPage < totalPages) {
            throttledGetUserForms({ pagination: { pageSize, currentPage: currentPage + 1 }, searchAndFilter, isFetchMore: true });
          }
        }
      }
    }, 50),
    [pagination]
  );

  useEffect(() => {
    paginationThrottled(pagination);
  }, [pagination])

  useEffect(() => {
    const { current } = scrollContainer;
    if (current) {
      current.addEventListener('scroll', fetchMore);
    }
    return () => {
      if (current) {
        current.removeEventListener('scroll', fetchMore);
      }
    };
  }, [fetchMore]);

  useEffect(() => {
    throttledGetUserForms({ pagination, searchAndFilter, forceOffline: showOffline });
    decoupledDispatch('Form.getStateData');
    getStats();
    getFormTemplate();
    getOrgCustomQuestions();
    syncOfflineForms();
    if (localStorage.getItem('defaultAssignment')) {
      setAssignment(localStorage.getItem('defaultAssignment'));
    }

    /* Disable 'no elastic scroll' on Safari iOS */
    noElasticScroll.disable();
    return () => {
      /* Enable 'no elastic scroll' on Safari iOS */
      noElasticScroll.enable();
    };
  }, []);

  useEffect(() => {
    throttledGetUserForms({ pagination, searchAndFilter });
  }, [online]);

  useEffect(() => {
    if (window.innerHeight < window.innerWidth && breakpoint === 'sm'
      && window.innerHeight <= BREAKPOINTS.xs.max && mobileCheck()) {
      createSnackNotification(
        AlertLevel.Warning,
        'Landscape mode isn\'t fully supported',
        'Use portrait mode for the best experience.');
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (assignment === 'Other') {
      otherAssignmentForm?.current?.focus();
    }
  }, [assignment]);

  useEffect(() => {
    if (officerAssignmentVal && (!assignment || !assignmentTypes.includes(assignment))) {
      const assignmentVal = officerAssignmentVal?.Patrol?.value;
      if (assignmentVal) {
        setAssignment(assignmentVal);
        localStorage.setItem('defaultAssignment', assignmentVal);
      }
    }
  }, [officerAssignmentVal])

  const onClickTab = (i: number) => {
    toggleFilterAndSearch(i);
    scrollContainer.current?.scrollTo?.(0, 0);
  };

  const assignmentTypes = [
    officerAssignmentVal?.Patrol.value,
    officerAssignmentVal?.Gang.value,
    officerAssignmentVal?.Compliance.value,
    officerAssignmentVal?.SpecialEvents.value,
    officerAssignmentVal?.RoadblockDUISobrietyCheckpoint.value,
    officerAssignmentVal?.Narcotics.value,
    officerAssignmentVal?.TaskForce.value,
    officerAssignmentVal?.K12.value,
    officerAssignmentVal?.Investigative.value,
    officerAssignmentVal?.Other.value,
  ];

  useEffect(() => {
    counterEffect(
      counter,
      intervalId,
      () => {
        setExceedTimeLimit(true);
      },
      () => {
        setExceedTimeLimit(false);
      }
    );
  }, [counter, intervalId]);

  const setDefaultAssignment = () => {
    const assignmentValue = localStorage.getItem('defaultAssignment') ?? officerAssignmentVal?.Patrol?.value;
    const val = assignmentValue !== officerAssignmentVal?.Other?.value ? assignmentValue : officerAssignmentVal?.Patrol?.value
    setAssignment(val);
    setOtherAssignment('');
    localStorage.setItem('defaultAssignment', val);
    if (localStorage.getItem('otherAssignmentValue')) {
      localStorage.removeItem('otherAssignmentValue');
    }
  }

  const onAssignmentClick = (value: string) => {
    localStorage.setItem('defaultAssignment', value);
    setAssignment(value);
    if (localStorage.getItem('otherAssignmentValue')) {
      localStorage.removeItem('otherAssignmentValue');
    }
  }

  const onOtherValueChange = (value: string) => {
    setOtherAssignment(value);
    localStorage.setItem('otherAssignmentValue', value);
  }

  return (
    <Header title="Contact" className={cn('dashboard-header', { 'has-training-mode-banner': trainingMode })}>
      <>
        {testingBanner && assignedRoles?.includes(Role.Admin) && <TestModeBanner />}
        {trainingMode && <TrainingModeBanner breakpoint={breakpoint} />}
        {breakpoint !== 'xs' && (
          <div className={cn('dashboard', { 'has-training-mode-banner': trainingMode })} data-testid="dashboard">
            <div className={cn('dashboard__filters', { offline: isWorkingOffline || showOffline })}>
              <div
                className={cn('dashboard__filters-offline-forms', { hide: showOffline ? false : !isWorkingOffline })}
                aria-label="Filter ripa report for ripa reports that were created offline"
                data-testid="dashboard-filters-offline-forms"
              >
                <div className="dashboard__filters-offline-forms-value">{offlineForms}</div>
                <div className="dashboard__filters-offline-forms-label">OFFLINE FORMS</div>
              </div>
              <div
                className={cn('dashboard__filters-created-today', { active: filterSelected.includes(0), hide: isWorkingOffline || showOffline })}
                aria-label="Filter ripa report for ripa reports that were created today"
                data-testid="dashboard-filters-created-today"
                onClick={() => toggleFilterAndSearch(0)}
                onKeyUp={onEnter(() => toggleFilterAndSearch(0))}
                role="button"
                tabIndex={0}
              >
                <div className="dashboard__filters-created-today-value">{last24HourForms ?? 0}</div>
                <div className="dashboard__filters-created-today-label">LAST 24 HOURS</div>
              </div>
              <div
                className={cn('dashboard__filters-draft-reports', { active: filterSelected.includes(1), hide: isWorkingOffline || showOffline })}
                data-testid="dashboard-filters-draft-reports"
                aria-label="Filter ripa report for ripa reports in draft status"
                onClick={() => toggleFilterAndSearch(1)}
                onKeyUp={onEnter(() => toggleFilterAndSearch(1))}
                role="button"
                tabIndex={0}
              >
                <div className="dashboard__filters-draft-reports-value">{draftStatus ?? 0}</div>
                <div className="dashboard__filters-draft-reports-label">DRAFT REPORTS</div>
              </div>
              <div
                className={cn('dashboard__filters-under-review', { active: filterSelected.includes(2), hide: isWorkingOffline || showOffline })}
                data-testid="dashboard-filters-under-review"
                aria-label="Filter ripa report for reports in review status"
                onClick={() => toggleFilterAndSearch(2)}
                onKeyUp={onEnter(() => toggleFilterAndSearch(2))}
                role="button"
                tabIndex={0}
              >
                <div className="dashboard__filters-under-review-value">{underReviewStatus ?? 0}</div>
                <div className="dashboard__filters-under-review-label">UNDER REVIEW</div>
              </div>
              <div
                className={cn('dashboard__filters-rejected-reports', { active: filterSelected.includes(3), hide: isWorkingOffline || showOffline })}
                data-testid="dashboard-filters-rejected-reports"
                aria-label="Filter ripa report for rejected reports"
                onClick={() => toggleFilterAndSearch(3)}
                onKeyUp={onEnter(() => toggleFilterAndSearch(3))}
                role="button"
                tabIndex={0}
              >
                <div className="dashboard__filters-rejected-reports-value">{rejectedStatus ?? 0}</div>
                <div className="dashboard__filters-rejected-reports-label">REJECTED FORMS</div>
              </div>
              <div
                className={cn('dashboard__filters-approved-reports', { active: filterSelected.includes(4), hide: isWorkingOffline || showOffline })}
                data-testid="dashboard-filters-approved-reports"
                aria-label="Filter ripa report for approved reports"
                onClick={() => toggleFilterAndSearch(4)}
                onKeyUp={onEnter(() => toggleFilterAndSearch(4))}
                role="button"
                tabIndex={0}
              >
                <div className="dashboard__filters-approved-reports-value">{approvedStatus ?? 0}</div>
                <div className="dashboard__filters-approved-reports-label">APPROVED FORMS</div>
              </div>
              <Button
                className="dashboard__filters-new-report"
                data-testid="dashboard-filters-new-report"
                priority="primary"
                large={true}
                disabled={!hasTemplate || !isUserSettingsValid}
                allowClickOnDisable={true}
                onClick={() => {
                  if (exceedTimeLimit) {
                    return workOnline();
                  }
                  return (
                    hasTemplate
                      ? isUserSettingsValid
                      ? createNewForm()
                      : createSnackNotification(AlertLevel.Warning, 'User Settings Are Required', `${userSettingsErrorMessage()} to create a new report.`)
                      : createSnackNotification(AlertLevel.Warning, 'Offline Report Creation Not Available', 'You need to have logged in once in online mode'))
                }}
                {...(!exceedTimeLimit && {
                  materialIcon: 'add',
                })}
              >
                {exceedTimeLimit ? 'LOG IN' : 'NEW REPORT'}
              </Button>
            </div>
            <div className={cn('dashboard__search-container', { offline: isWorkingOffline || showOffline })}>
              <TextField
                className={cn('dashboard__search', { offline: isWorkingOffline || showOffline })}
                variant="outlined"
                placeholder="Search..."
                onChange={({ target: { value } }) => setSearch(value || undefined)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className="material-icons">search</div>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  'data-testid': 'dashboard-search',
                }}
              />
              <Button
                className="dashboard__search-new-report"
                priority="primary"
                large={true}
                disabled={!hasTemplate || !isUserSettingsValid}
                allowClickOnDisable={true}
                onClick={() => {
                  if (exceedTimeLimit) {
                    return workOnline();
                  }
                  return (
                    hasTemplate
                      ? isUserSettingsValid
                      ? createNewForm()
                      : createSnackNotification(AlertLevel.Warning, 'User Settings Are Required', `${userSettingsErrorMessage()} to create a new report.`)
                      : createSnackNotification(AlertLevel.Warning, 'Offline Report Creation Not Available', 'You need to have logged in once in online mode')
                  )
                }}
                {...(!exceedTimeLimit && {
                  materialIcon: 'add',
                })}
              >
                {exceedTimeLimit ? 'LOG IN' : 'NEW REPORT'}
              </Button>
            </div>
            <div className="dashboard__table">
              <Table
                trainingMode={trainingMode}
                RowComponent={ReportRow}
                rowData={trainingMode ? [] : reportData}
                dataQuery={({ page, limit }: { page: number; limit: number }) =>
                  getUserForms({
                    searchAndFilter: { ...searchAndFilter, sort },
                    pagination: { currentPage: page, pageSize: limit },
                    forceOffline: showOffline,
                    isFetchMore: undefined,
                  })
                }
                disableOnlineFeatures={isWorkingOffline || showOffline}
                loading={loading.getUserForms}
                pagination={pagination}
                setSort={setSort}
                setPagination={setPagination}
                emptyMessage={trainingMode ? 'No forms are saved in training mode' : isWorkingOffline || showOffline ? 'There are no offline forms.' : 'There are no forms.'}
              >
                <Column title="Location" dataKey="location" grow={5} minWidth={140} />
                <Column title="Assignment" dataKey="assignment" grow={3} />
                <Column title="Time" dataKey="time" grow={4} minWidth={75} sort={sortTime('time')} />
                <Column title="people" dataKey="people" grow={1} sort={sortNumber('people')} />
                {breakpoint === 'lg' ? (
                  <Column title="Progress" dataKey="progress" grow={1} sort={sortNumber('progress')} />
                ) : (
                  <Column title="Prog" dataKey="progress" grow={1} sort={sortNumber('progress')} />
                )}
                <Column title="Status" dataKey="status" grow={3} minWidth={100} sort={sortText('status')} />
                <Column title="Actions" dataKey="actions" grow={3} minWidth={87} />
              </Table>
            </div>
          </div>
        )}
        <ConfirmDialog
          heading="FORM ERRORS"
          open={reviewFeedbackDialogOpen}
          closeDialog={() => setReviewFeedbackDialogOpen({ open: false, formId: undefined })}
          positiveAction={() => setReviewFeedbackDialogOpen({ open: false, formId: undefined })}
          positiveText="Ok"
        >
          <div className="dashboard__review-feedback-dialog">
            <div className="dashboard__review-feedback-dialog-heading">The following are issues that need to be fixed</div>
            {reviewFeedbackDialogContent?.noteForReviewee !== undefined && (
              <div className="dashboard__review-feedback-dialog-content" data-testid="dashboard-review-feedback-dialog-content">
                {/* noteForReviewee is for legacy comment support */}
                {reviewFeedbackDialogContent?.noteForReviewee?.trim() || 'No feedback given from reviewer'}
              </div>
            )}
            {reviewFeedbackDialogContent?.noteHistory?.length > 0 && (
              <div className="dashboard__review-feedback-dialog-content">
                {reviewFeedbackDialogContent.noteHistory.slice(0, showAllComments ? reviewFeedbackDialogContent.noteHistory.length : 1).map((note: ReviewFeedbackNote, i: number) => (
                  <>
                    <div className="dashboard__history-content" data-testid="dashboard-history-content" key={`DashboardFeedbackDialogHistory-${i}`}>
                      <div className="dashboard__history-content-heading">
                        <div className="dashboard__history-content-date">{dayjs(note.date).format('MM/DD/YYYY h:mm A')}</div>
                        <div className={cn('dashboard__history-content-label', { previous: i === 1 })}>{i === 0 ? 'New Comment' : i === 1 ? 'Previous Comment(s)' : ''}</div>
                      </div>
                      {note.reviewFeedback?.trim() || 'No feedback given from reviewer'}
                      <div className="dashboard__history-content-reviewer" data-testid="dashboard-history-content-reviewer">
                        {`Reviewed by: ${note.reviewer.last_name}, ${note.reviewer.first_name}`}
                      </div>
                    </div>
                  </>
                ))}
                {reviewFeedbackDialogContent?.noteHistory?.length > 1 && (
                  <div
                    className="dashboard__history-content-show-button"
                    data-testid="dashboard-history-content-show-button"
                    onClick={() => toggleShowAllComments()}
                    onKeyUp={onEnter(() => toggleShowAllComments())}
                    role="button"
                    tabIndex={0}
                  >
                    {showAllComments ? 'Show less' : 'Show Previous'}
                    <span className="material-icons">{showAllComments ? 'remove' : 'add'}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </ConfirmDialog>
        {breakpoint === 'xs' && (
          <div className={cn('dashboard-mobile', { testingBanner })} data-testid="dashboard-mobile">
            <div className="dashboard-mobile__assignment-container">
              <div className="dashboard-mobile__assignment-dropdown-container">
                {officerAssignmentVal && assignment !== 'Other' && otherAssignment === '' && (
                  <FormControl
                    variant="outlined"
                    className="dashboard-mobile__assignment-dropdown"
                  >
                    <Select
                      labelId="dashboard-mobile-assignment-label"
                      id="dashboard-mobile-assignment"
                      className="dashboard-mobile-assignment"
                      value={assignment}
                      onChange={({ target: { value } }: any) => onAssignmentClick(value)}
                      native={breakpoint === 'xs' && !location.href.includes('contact-dev')}
                      data-testid="dashboard-mobile-assignment"
                      MenuProps={{
                        variant: 'menu',
                        getContentAnchorEl: null
                      }}
                    >
                      {assignmentTypes.map((t, i) => {
                        const CompName = breakpoint === 'xs' ? 'option' : MenuItem;
                        return (<CompName value={t} key={`assignment-option-${i}`}>
                          {t}
                        </CompName>);
                      })}
                    </Select>
                  </FormControl>
                )}
                {(assignment === officerAssignmentVal?.Other.value ||
                  otherAssignment !== '') && (
                    <TextField
                      inputRef={otherAssignmentForm}
                      id="dashboard-mobile__other-assignment"
                      className="dashboard-mobile__other-assignment"
                      InputProps={{
                        endAdornment: (
                          <div className="dashboard-mobile__other-assignment-controls">
                            <div
                              className="dashboard-mobile__other-assignment-back material-icons"
                              role="button"
                              tabIndex={0}
                              onClick={setDefaultAssignment}
                              onKeyUp={onEnter(setDefaultAssignment)}
                            >
                              close
                            </div>
                          </div>
                        ),
                      }}
                      placeholder="Enter Other Assignment Title..."
                      value={otherAssignmentValue}
                      onChange={(e) => onOtherValueChange(e?.target?.value)}
                      onBlur={() => setOtherAssignment(otherAssignment)}
                      variant="outlined"
                      type="text"
                      data-testid="dashboard-mobile__other-assignment"
                      required
                    />
                  )}
              </div>
            </div>
            <div className="dashboard-mobile__search-container">
              <div className={cn('dashboard-mobile__search-new-report-container', {
                'login-offline': exceedTimeLimit,
              })}>
                <TextField
                  className="dashboard-mobile__search"
                  variant="outlined"
                  placeholder="Search..."
                  onChange={({ target: { value } }) => setSearch(value || undefined)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div className="material-icons">search</div>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  className="dashboard-mobile__new-report"
                  priority="primary"
                  disabled={!hasTemplate || !isUserSettingsValid}
                  allowClickOnDisable={true}
                  onClick={() => {
                    if (exceedTimeLimit) {
                      return workOnline();
                    }
                    return (
                      hasTemplate
                        ? isUserSettingsValid
                        ? createNewForm()
                        : createSnackNotification(AlertLevel.Warning, 'User Settings Are Required', `${userSettingsErrorMessage()} to create a new report.`)
                        : createSnackNotification(AlertLevel.Warning, 'Offline Report Creation Not Available', 'You need to have logged in once in online mode')
                    );
                  }}
                  {...(!exceedTimeLimit && {
                    materialIcon: 'add',
                  })}
                >
                  {exceedTimeLimit ? 'LOG IN' : 'NEW REPORT'}
                </Button>
              </div>
              <div className={cn('dashboard-mobile__filters', { offline: isWorkingOffline })}>
                <div
                  className={cn('dashboard-mobile__filters-offline-forms', { hide: showOffline ? false : !isWorkingOffline })}
                  aria-label="Filter ripa report for ripa reports that were created offline"
                  data-testid="dashboard-filters-offline-forms"
                >
                  <div className="dashboard-mobile__filters-offline-forms-value">{offlineForms ?? 0}</div>
                  <div className="dashboard-mobile__filters-offline-forms-label">OFFLINE FORMS</div>
                </div>
                <div
                  className={cn('dashboard-mobile__filters-created-today', { active: filterSelected.includes(0), hide: isWorkingOffline || showOffline })}
                  aria-label="Filter ripa report for ripa reports created today"
                  data-testid="dashboard-mobile-filters-created-today"
                  onClick={() => onClickTab(0)}
                  onKeyUp={onEnter(() => onClickTab(0))}
                  role="button"
                  tabIndex={0}
                >
                  <div className="dashboard-mobile__filters-created-today-value">{last24HourForms ?? 0}</div>
                  <div className="dashboard-mobile__filters-created-today-label">LAST 24</div>
                </div>
                <div
                  className={cn('dashboard-mobile__filters-draft-reports', { active: filterSelected.includes(1), hide: isWorkingOffline || showOffline })}
                  aria-label="Filter ripa report for ripa reports that are currently in draft status"
                  data-testid="dashboard-mobile-filters-draft-reports"
                  onClick={() => onClickTab(1)}
                  onKeyUp={onEnter(() => onClickTab(1))}
                  role="button"
                  tabIndex={0}
                >
                  <div className="dashboard-mobile__filters-draft-reports-value">{draftStatus ?? 0}</div>
                  <div className="dashboard-mobile__filters-draft-reports-label">DRAFT</div>
                </div>
                <div
                  className={cn('dashboard-mobile__filters-under-review', { active: filterSelected.includes(2), hide: isWorkingOffline || showOffline })}
                  aria-label="Filter ripa report for under review reports"
                  data-testid="dashboard-mobile-filters-under-review"
                  onClick={() => onClickTab(2)}
                  onKeyUp={onEnter(() => onClickTab(2))}
                  role="button"
                  tabIndex={0}
                >
                  <div className="dashboard-mobile__filters-under-review-value">{underReviewStatus ?? 0}</div>
                  <div className="dashboard-mobile__filters-under-review-label">REVIEW</div>
                </div>
                <div
                  className={cn('dashboard-mobile__filters-rejected-reports', { active: filterSelected.includes(3), hide: isWorkingOffline || showOffline })}
                  aria-label="Filter ripa report for rejected reports"
                  data-testid="dashboard-mobile-filters-rejected-reports"
                  onClick={() => onClickTab(3)}
                  onKeyUp={onEnter(() => onClickTab(3))}
                  role="button"
                  tabIndex={0}
                >
                  <div className="dashboard-mobile__filters-rejected-reports-value">{rejectedStatus ?? 0}</div>
                  <div className="dashboard-mobile__filters-rejected-reports-label">REJECTED</div>
                </div>
                <div
                  className={cn('dashboard-mobile__filters-rejected-reports', { active: filterSelected.includes(4), hide: isWorkingOffline || showOffline })}
                  aria-label="Filter ripa report for rejected reports"
                  data-testid="dashboard-mobile-filters-approved-reports"
                  onClick={() => onClickTab(4)}
                  onKeyUp={onEnter(() => onClickTab(4))}
                  role="button"
                  tabIndex={0}
                >
                  <div className="dashboard-mobile__filters-rejected-reports-value">{approvedStatus}</div>
                  <div className="dashboard-mobile__filters-rejected-reports-label">APPROVED</div>
                </div>
              </div>
            </div>
            {loading.getUserForms && <LinearProgress />}
            <div className="dashboard-mobile__cards" data-testid="dashboard-mobile-cards" ref={scrollContainer}>
              {!trainingMode &&
                reportData.map(({ id, location, assignment: reportDataAssignment, time, people, progress, k12, status, offlineStatus }, i) => (
                  <div className="dashboard-mobile__card" key={`DashboardMobileCard-${i}`} data-testid={`dashboard-mobile-card-${id}-${i}`}>
                    {[Status.Draft, Status.Rejected].includes(status) && <MoreDashboardActions deleteForm={deleteForm} i={i} id={id} status={status} />}
                    <div className="dashboard-mobile__card-location">{location || 'Location Pending'}</div>
                    {[Status.Rejected].includes(status) && (
                      <div
                        className="dashboard__table-cell-action-rejected-dialog material-icons"
                        role="button"
                        tabIndex={0}
                        data-testid="dashboard-table-cell-rejected-dialog"
                        aria-label="Show rejection notes"
                        onClick={() => setReviewFeedbackDialogOpen({ open: true, formId: id })}
                        onKeyUp={onEnter(() => setReviewFeedbackDialogOpen({ open: true, formId: id }))}
                      >
                        priority_high
                      </div>
                    )}
                    <div className="dashboard-mobile__card-assignment">{reportDataAssignment}</div>
                    <div className="dashboard-mobile__card-time-status-container">
                      <div className="dashboard-mobile__card-time">{time.format('MM/DD/YYYY HH:mm')}</div>
                      <div
                        className={cn('dashboard-mobile__card-status', {
                          offline: offlineStatus === Status.Offline,
                          syncing: offlineStatus === Status.Syncing,
                          synced: offlineStatus === Status.Synced,
                          syncfailed: offlineStatus === Status.SyncFailed,
                        })}
                      >
                        {StatusString?.[offlineStatus]}
                      </div>
                      <div
                        className={cn('dashboard-mobile__card-status', {
                          draft: status === Status.Draft,
                          under_review: status === Status.UnderReview,
                          approved: status === Status.Approved,
                          rejected: status === Status.Rejected,
                          denied: status === Status.DeniedByDOJ,
                          submitted: [Status.SubmittedToDOJ, Status.SubmittingToDOJ].includes(status),
                        })}
                      >
                        {StatusString?.[status]}
                      </div>
                    </div>
                    <div className="dashboard-mobile__card-details">
                      <div className="dashboard-mobile__card-people">
                        <div className="dashboard-mobile__card-people-icon material-icons">person</div>
                        <div className="dashboard-mobile__card-people-value">{people}</div>
                      </div>
                      <div className="dashboard-mobile__card-k12">
                        <div className={cn('dashboard-mobile__card-k12-icon material-icons', { k12 })}>{k12 ? 'done' : 'block'}</div>
                        <div className="dashboard-mobile__card-k12-label">K-12</div>
                      </div>
                      <div className="dashboard-mobile__card-progress">
                        <div
                          className={cn('dashboard-mobile__card-progress-value', {
                            red: progress < 50,
                            orange: progress >= 50 && progress < 80,
                            green: progress >= 80,
                          })}
                        >
                          {`${progress}%`}
                        </div>
                        <div className="dashboard-mobile__card-progress-label">Complete</div>
                      </div>
                    </div>
                  </div>
                ))}
              {(trainingMode || reportData.length === 0) && (
                <div className="dashboard-mobile__cards-empty">{trainingMode ? 'No forms are saved in training mode' : isWorkingOffline ? 'There are no offline forms.' : 'There are no forms.'}</div>
              )}
            </div>
          </div>
        )}
      </>
    </Header>
  );
};

export default composer(Dashboard);
