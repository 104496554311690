import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { onEnter } from '@utility/keypressHelpers';
import './MoreActions.scss';

export const MoreAdminUserActions = ({ i, id, userId, setEditUserDialogOpen, setDisableUserDialogOpen, userDisabled }: Props) => {
  const [cardMenuOpen, setCardMenuOpen] = useState<HTMLDivElement | null>(null);
  const toggleCardMenuOpen = (open: boolean) => (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    setCardMenuOpen(open ? e.currentTarget : null);
  };

  const openEditDialog = () => {
    setCardMenuOpen(null);
    setEditUserDialogOpen({ open: true, id });
  };

  const openDisableDialog = () => {
    setCardMenuOpen(null);
    setDisableUserDialogOpen({ open: true, id });
  };

  return (
    <>
      <Menu
        anchorEl={cardMenuOpen}
        open={cardMenuOpen?.id === `users-mobile-card-action-${i}`}
        onClose={toggleCardMenuOpen(false)}
        data-testid={`users-mobile-card-action-menu-${i}`}
      >
        <MenuItem data-testid="users-mobile-menu-item-edit" onClick={openEditDialog}>
          Edit
        </MenuItem>
        {!userDisabled && (userId !== id) && (
          <MenuItem data-testid="users-mobile-menu-item-delete" onClick={openDisableDialog}>
            Disable
          </MenuItem>
        )}
      </Menu>
      <div
        className="users-mobile__card-actions material-icons"
        data-testid={`users-mobile-card-actions-${i}`}
        id={`users-mobile-card-action-${i}`}
        tabIndex={0}
        role="button"
        aria-label="Opens the ripa actions related to this card"
        aria-haspopup="true"
        onClick={toggleCardMenuOpen(true)}
        onKeyUp={onEnter(toggleCardMenuOpen(true))}
      >
        more_vert
      </div>
    </>
  );
};

interface Props {
  i: number;
  id: number;
  userId?: number;
  setEditUserDialogOpen: ({ open, id }: { open: boolean; id: number }) => void;
  setDisableUserDialogOpen: ({ open, id }: { open: boolean; id: number}) => void;
  userDisabled: boolean;
}

export default MoreAdminUserActions;
