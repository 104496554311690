import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { path } from 'ramda';
import { setValidationErrors } from '@ducks/form';
import { Props, connector } from './props';
import './DynamicTextForm.scss';

let helperRef: React.RefObject<HTMLDivElement>;

const DynamicTextForm = ({ title, resultPath, format, form, onChange, style, currentPerson, labels, numberOfPeople, isAll, defaultValue, placeholder, maxLength, multiline }: Props) => {
  const [text, setText] = useState<string>();
  const [reset, setReset] = useState<boolean>();
  helperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let v;
    if (isAll) {
      v = path(['custom', 'person', currentPerson, resultPath], form);
    } else {
      v = path(['custom', resultPath], form);
    }

    const defaultValueParsed = defaultValue;

    if (v === null || v === undefined) {
      v = defaultValueParsed;
    }

    setValueAndClearValidation(v);
    setReset(true);
  }, [resultPath, isAll, currentPerson, defaultValue]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const setValueAndClearValidation = (value: string) => {
    if (isAll) {
      setText(value);
      onChange(value, ['custom', 'person', currentPerson, resultPath]);
    } else {
      setText(value);
      onChange(value, ['custom', resultPath]);
    }
    setValidationErrors?.({});
  };

  if (reset) {
    return <div />;
  }

  return (
    <div className="dynamic-text-form" data-testid="dynamic-text-form" style={{ ...style, height: `calc(100% - ${helperRef.current?.clientHeight ?? 0}px)` }} key={`${resultPath}x${currentPerson}`}>
      {isAll && (
        <div className="dynamic-text-form__person">
          <div className="material-icons">person</div>
          <div className="dynamic-text-form__person-label">{labels?.[currentPerson] || currentPerson + 1}</div>
          <div className="dynamic-text-form__person-same-for-all">Same For All</div>
          <div className="dynamic-text-form__person-progression">
            <b>{`${currentPerson + 1}`}</b>
            {`/${numberOfPeople}`}
          </div>
          <div className="dynamic-text-form__person-progression-all">ALL</div>
        </div>
      )}
      <div className="dynamic-text-form__title">{title}</div>
      <div className="dynamic-text-form__field-container">
        <TextField
          className="dynamic-text-form__field"
          placeholder={placeholder}
          multiline={multiline}
          onChange={({ target: { value } }) => setValueAndClearValidation(value)}
          value={text}
          variant="outlined"
          inputProps={{
            pattern: format || false,
            'data-testid': 'dynamic-text-form-input',
            maxlength: maxLength || 50,
          }}
        />
      </div>
    </div>
  );
};

const Helper = ({ HelperTextInfo }: { HelperTextInfo: string }) => {
  if (HelperTextInfo) {
    return (
      <div className="ripa-form-container__helper-box" ref={helperRef}>
        <div className="material-icons">help</div>
        <div className="ripa-form-container__helper-box-text">{HelperTextInfo}</div>
      </div>
    );
  }
  return null;
};

DynamicTextForm.helper = Helper;

export default connector(DynamicTextForm);
