export default function pendoInit(userId: string | number, userEmail: string, orgId: string | number, orgName: string) {
  const config = {
    visitor: {
      id: userId,
      email: userEmail
    },

    account: {
      id: orgId,
      name: orgName
    }
  };
  window.pendo?.initialize(config);
}
