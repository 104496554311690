import { matchSorter } from 'match-sorter';

// Leaving commented to show what could meet basic needs but since we have match-sorter already, just use it

// function normalizeString(str: string): string {
//   return str.toLowerCase()
//     .normalize('NFD')
//     .replace(/[\u0300-\u036f]/g, '')
//     .replace(/\s+/g, '');
// }

function findCity(input: string): string {
  if (!window.cities || !Array.isArray(window.cities)) {
    console.error('window.cities is not defined or not an array');
    return '';
  }

  // const formattedQuery = normalizeString(input);

  // let result = window.cities.find((city: { City: string }) => {
  //   const formattedCity = normalizeString(city.City);
  //   return formattedCity === formattedQuery;
  // });
  // result ||= window.cities.find((city: { City: string }) => {
  //   const formattedCity = normalizeString(city.City);
  //   return formattedCity.includes(formattedQuery);
  // });
  // return result ? result.City : '';

  const result = matchSorter(window.cities, input, { keys: ['City'] })

  // First result should be the best match
  return result?.[0]?.City || '';
}

export { findCity };
